import React from 'react';
import styles from './MessageIconHeader.module.scss';
import { MESSAGES_VARIANT } from './const';

interface IMessagesIconHeader {
  variant?: MESSAGES_VARIANT;
}

const CLASS_MESSAGE = {
  [MESSAGES_VARIANT.NEW_MESSAGE]: styles.iconActive,
  [MESSAGES_VARIANT.NO_MESSAGE]: '',
};

const MessagesIconHeader: React.FC<IMessagesIconHeader> = ({ variant }) => (
  <div className={styles.wrapper}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" fill="none" viewBox="0 0 24 20">
      <path
        fill="#133E67"
        fillRule="evenodd"
        d="M21.6 0H2.4C1.08 0 .012 1.08.012 2.4L0 16.8c0 1.32 1.08 2.4 2.4 2.4h19.2c1.32 0 2.4-1.08 2.4-2.4V2.4C24 1.08 22.92 0 21.6 0zm0 4.8l-9.6 6-9.6-6V2.4l9.6 6 9.6-6v2.4z"
        clipRule="evenodd"
      />
    </svg>
    <span className={CLASS_MESSAGE[variant || MESSAGES_VARIANT.NO_MESSAGE]} />
  </div>
);

MessagesIconHeader.defaultProps = {
  variant: MESSAGES_VARIANT.NO_MESSAGE,
};

export default MessagesIconHeader;
