import styles from './InformationModal.module.scss';

const InformationModal = () => (
  <div className={styles.modalWrapper}>
    <span className={styles.modalWrapper_title}>Requirements</span>
    <ul>
      <li>a. Must be at least 12 characters</li>
      <li>b. Two lowercase and uppercase letters</li>
      <li>c. No spaces</li>
      <li>d. Two number and two special character (example, ! @ # $% ^ & *)')</li>
    </ul>
  </div>
);

export default InformationModal;
