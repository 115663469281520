import { Dispatch, SetStateAction, useCallback } from 'react';
import { TApiResponse } from '../../common';

const useRequest = <T, R>(
  request: (...args: any[]) => Promise<TApiResponse<T>>,
  setState: Dispatch<SetStateAction<R>>,
  resolver?: (value: TApiResponse<T>, params?: unknown[]) => TApiResponse<T> | PromiseLike<TApiResponse<T>> | void,
) =>
  useCallback(
    async (...params): Promise<TApiResponse<T> | void> => {
      setState((p) => ({ ...p, loading: true }));

      return request(...params)
        .then((result) => (resolver ? resolver(result, params) : undefined))
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          setState((p) => ({ ...p, loading: false }));
        });
    },
    [request, resolver, setState],
  );

export default useRequest;
