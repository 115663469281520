export const formateDate = (date: string) =>
  new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

export const timeDiffCalc = (date: string) => {
  const dateNow = +new Date();
  const dateToLogin = +new Date(date);
  let diffInMilliSeconds = Math.abs(+dateNow - dateToLogin) / 1000;
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  let difference = '';
  difference += `${hours ? `${hours} hours ` : ''}${minutes} minutes`;
  return difference;
};
