import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import styles from '../styles.module.scss';
import SelectComponent from '../../../components/Select';
import Button, { ICON_POSITION } from '../../../components/Button';
import { ReactComponent as PlusSignIcon } from '../../../components/Icons/PlusSignIcon.svg';
import TableSystemUser from '../List';
import { urlSystemUserCreate } from '../../../url/urlSystemUsers';
import { SystemUserRoleSelect, SystemUserStatusSelect } from '../../../interfaces/ISelect';
import { store } from '../../../store';
import { accessToken, getOpcoIdFromToken } from '../../../utils/tokens';

const SystemUserList = () => {
  const history = useHistory();
  const {
    state: { authState },
  } = useContext(store);
  const id = authState.opcoId || getOpcoIdFromToken(accessToken.get());
  const handleNewSystemUser = useCallback(() => {
    history.push(urlSystemUserCreate({ id }));
  }, [history, id]);

  return (
    <>
      <PageHeader
        label="CrediVault Users"
        renderControls={() => (
          <div className={styles.headerBlock}>
            <div className={styles.selectWrapper}>
              <SelectComponent
                placeholder="Filter by Role"
                onChange={() => {}}
                options={SystemUserRoleSelect}
                className="SystemUserSelect"
              />
            </div>
            <div className={styles.selectWrapper}>
              <SelectComponent
                placeholder="Filter by Status"
                onChange={() => {}}
                options={SystemUserStatusSelect}
                className="SystemUserSelect"
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button onClick={handleNewSystemUser} iconPosition={ICON_POSITION.RIGHT} icon={<PlusSignIcon />}>
                Add New User
              </Button>
            </div>
          </div>
        )}
      />
      <TableSystemUser />
    </>
  );
};
export default SystemUserList;
