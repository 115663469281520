import React from 'react';
import classes from './styles.module.scss';

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface ISortsSwitchesProps {
  active?: boolean;
  order?: Order;
  onClick: Function;
}

const SortsSwitches = ({ active, order, onClick }: ISortsSwitchesProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div onClick={() => onClick()}>
    <div className={`${classes.triangleUp} ${active && order === 'ASC' ? classes.activeTriangle : ''}`} />
    <div className={`${classes.triangleDown} ${active && order === 'DESC' ? classes.activeTriangle : ''}`} />
  </div>
);

SortsSwitches.defaultProps = {
  active: false,
  order: null,
};

export default SortsSwitches;
