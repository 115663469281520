import React from 'react';

const SystemUsers = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="none" viewBox="0 0 22 21">
    <path
      stroke="#F5F6F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M7.684 9.58l2.842 2.841L20 2.948"
    />
    <path
      stroke="#F5F6F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M19.053 10.526v6.632c0 .502-.2.984-.555 1.34-.356.355-.838.555-1.34.555H3.895c-.503 0-.985-.2-1.34-.555C2.2 18.142 2 17.66 2 17.158V3.895c0-.503.2-.985.555-1.34C2.91 2.2 3.392 2 3.895 2h10.42"
    />
  </svg>
);

export default SystemUsers;
