/* eslint-disable @typescript-eslint/no-explicit-any */

import { TApiResponse } from '../../../common';
import instance from '../instance';

export interface IIndustry {
  id: string;
  title: string;
  isActive: boolean;
}

const getIndustries = async (): Promise<TApiResponse<IIndustry>> => instance.get('/industries/');

export default getIndustries;
