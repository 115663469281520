import React, { useCallback } from 'react';
import classNames from 'classnames';
import { IInput, IValues } from '../Forms/Models/FormModels';

import styles from './InputComponent.module.scss';
import { INPUT_VARIANT } from './const';

interface IInputItem extends IInput {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  onKeyDown?: Function;
  placeholder?: string;
  classes?: string;
  value?: string;
  values?: IValues;
  touched?: object;
  error?: object;
  variant?: INPUT_VARIANT;
}

const INPUT_VARIANT_MAP = {
  [INPUT_VARIANT.LOGIN_INPUT]: styles.inputField,
  [INPUT_VARIANT.ERROR_INPUT]: styles.errorInputStyle,
  [INPUT_VARIANT.DEFAULT_INPUT]: styles.default,
};

const defaultProps = {
  placeholder: '',
  onChange: () => {},
  onKeyDown: () => {},
};

const InputComponent: React.FC<IInputItem> = ({
  onChange,
  values,
  placeholder,
  name,
  handleBlur,
  variant,
  type,
  onClick,
}) => {
  // const changeHandler = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     onChange(e.target.value);
  //   },
  //   [onChange],
  // );
  const keyDownHandler = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      // eslint-disable-next-line
      console.log('EnterPress event for search');
    }
  }, []);
  return (
    <div>
      <input
        id={name}
        placeholder={placeholder}
        className={classNames(INPUT_VARIANT_MAP[variant ?? INPUT_VARIANT.DEFAULT_INPUT])}
        onKeyDown={keyDownHandler}
        // @ts-ignore
        value={values[name]}
        name={name}
        onChange={onChange}
        onBlur={handleBlur}
        type={type}
        onClick={onClick}
      />
    </div>
  );
};

InputComponent.defaultProps = defaultProps;

export default InputComponent;
