import { TOrdering } from '../../../components/Table';
import { TApiResponse } from '../../../common';
import { TSystemUser } from '../../../models';
import mkRequestOrdering from '../../../utils/mkRequestOrdering';
import instance from '../instance';

export interface RequestParams {
  id: string;
  opcoId?: string;
  ordering?: TOrdering;
  current_status__in?: string;
  limit?: number;
  offset?: number;
}

const getSystemUsers = async (params: RequestParams): Promise<TApiResponse<TSystemUser[]>> => {
  const orderingParam = mkRequestOrdering(params?.ordering);
  const { limit, offset, opcoId } = params ?? {};
  const requestParams = { params: { ordering: orderingParam, limit, offset } };

  return instance.get(`opco/${opcoId}/users/`, requestParams);
};

const getSystemUserDetail = async (params: RequestParams): Promise<TApiResponse<TSystemUser>> => {
  const { opcoId, id } = params ?? {};

  return instance.get(`opco/${opcoId}/users/${id}`);
};

export { getSystemUsers, getSystemUserDetail };
