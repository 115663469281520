import { Link } from 'react-router-dom';
import { TUserOpco } from '../../models';
import { ITableColumn } from '../../components/Table';
import Status from '../../components/Status/Status';
import { LABEL_VARIANTS } from '../../components/Status';
import Button, { BUTTON_VARIANTS } from '../../components/Button';
import { capitalise } from '../../utils/capitalize';
import { urlOrgManagmentUserDetails, urlOrgManagmentUserEdit } from '../../url/urlOrgManagementItem';

const mkColumnsUser = (opcoId: string): ITableColumn<TUserOpco>[] => [
  {
    header: 'Email Address',
    order: { name: 'email' },
    renderer: ({ email, id }) => <Link to={urlOrgManagmentUserDetails({ id: opcoId, userId: id })}>{email}</Link>,
  },
  {
    header: 'Full Name',
    order: { name: 'name' },
    renderer: ({ name }) => <span>{name}</span>,
  },
  {
    header: 'Role',
    order: { name: 'role' },
    renderer: ({ role }) => <span>{capitalise(role)}</span>,
  },
  {
    header: 'Status',
    order: { name: 'status' },
    renderer: ({ status }) => <Status variant={capitalise(status) as LABEL_VARIANTS} />,
  },
  {
    header: '',
    renderer: ({ id }) => (
      <Button path={urlOrgManagmentUserEdit({ id: opcoId, userId: id })} variant={BUTTON_VARIANTS.EDIT}>
        edit
      </Button>
    ),
  },
];

export { mkColumnsUser };
