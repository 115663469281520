import React from 'react';
import { Field } from 'formik';
import styles from './RadioButton.module.scss';

interface IRadioButton {
  variant: Array<{ title: string; value: string; name: string; id: string }>;
}

const RadioButton: React.FC<IRadioButton> = ({ variant }) => (
  <>
    {variant.map((el) => (
      <div className={styles.radioBlock} key={el.id}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.container}>
          <Field type="radio" name={el.name} className={styles.input} value={el.value} />
          <span className={styles.fakeRadio} />
          <span className={styles.title}>{el.title}</span>
        </label>
      </div>
    ))}
  </>
);

export default RadioButton;
