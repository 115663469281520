import { useMemo, useState } from 'react';
import { TUserOpco } from '../../models';
import { getUserOpCo, getUserDetailsOpCo } from '../../api/private/opco/organizations/get';
import useRequest from '../../utils/customHooks/useRequest';

export interface IUserOpcoState {
  loading: boolean;
  userOpcoList: TUserOpco[] | null;
  userOpcoListItem: TUserOpco | null;
  itemTotalCount?: number;
}

export const useUserOpcoState = () => {
  const [state, setState] = useState<IUserOpcoState>({
    loading: false,
    userOpcoList: null,
    userOpcoListItem: null,
    itemTotalCount: undefined,
  });

  const getOpcoUser = useRequest<TUserOpco[], IUserOpcoState>(getUserOpCo, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      userOpcoList: data.result,
      itemTotalCount: data.items_total_count ?? 0,
    }));
  });

  const getOpcoUserDetails = useRequest<TUserOpco, IUserOpcoState>(getUserDetailsOpCo, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      userOpcoListItem: data.result,
    }));
  });

  return useMemo(
    () => ({
      get isLoading() {
        return state.loading;
      },
      get userOpcoList() {
        return state.userOpcoList;
      },
      get userOpcoListItem() {
        return state.userOpcoListItem;
      },
      get itemTotalCount() {
        return state.itemTotalCount;
      },
      getOpcoUser,
      getOpcoUserDetails,
    }),
    [getOpcoUser, getOpcoUserDetails, state.loading, state.userOpcoList, state.itemTotalCount], //  eslint-disable-line react-hooks/exhaustive-deps
  );
};
