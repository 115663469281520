/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import { GlobalState } from './state';

const proxyMethodCalls = (obj: unknown, postAction: () => void) => {
  const handler = {
    get(target: never, propKey: never) {
      const originalMethod = target[propKey];

      if (typeof originalMethod == 'function' && !(propKey as string).startsWith('_')) {
        // TODO: enhance via decorators + metadata
        // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
        return function (...args: any) {
          // @ts-ignore
          const result = originalMethod.apply(target, args);
          // Doesn't matter if it's Promise or not we should call update after all
          return Promise.resolve(result).then((value) => {
            postAction();
            return value;
          });
        };
      }
      return originalMethod;
    },
  };
  return new Proxy(obj as object, handler);
};

const wrapRootState = (rootState: GlobalState, postAction: () => void) => {
  for (const state of Object.entries(rootState)) {
    state[1].__storeContext = { postAction };
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    rootState[state[0]] = proxyMethodCalls(state[1], postAction);
  }
};

const useStore = (initialState: GlobalState) => {
  const [state, setState] = useState(initialState);
  // @ts-ignore
  if (!state.__proxied) {
    wrapRootState(state, () => setState({ ...state }));
    // @ts-ignore
    state.__proxied = true;
  }

  return state;
};

export default useStore;
