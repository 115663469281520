import { useMemo, useState } from 'react';
import { PhoneCode, getPhoneCodesFirst, getPhoneCodesSecond } from '../../api/private/phoneCodes/get';
import useRequest from '../../utils/customHooks/useRequest';

export interface IPhoneCodeState {
  loading: boolean;
  phoneCodesFirst: PhoneCode[] | null;
  phoneCodesSecond: PhoneCode[] | null;
  phoneCodes: PhoneCode[] | null;
  itemTotalCount?: number;
  requestError: string | null;
}

export const usePhoneCodesState = () => {
  const [state, setState] = useState<IPhoneCodeState>({
    loading: false,
    phoneCodesFirst: null,
    phoneCodesSecond: null,
    phoneCodes: null,
    itemTotalCount: undefined,
    requestError: null,
  });

  const getPhonesFirst = useRequest<PhoneCode[], IPhoneCodeState>(getPhoneCodesFirst, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      phoneCodes:
        data.result &&
        data.result.map((item: PhoneCode) => {
          const newItem = { ...item };
          return {
            ...newItem,
            label: `+${newItem.phone_code}`,
            value: newItem.phone_code,
          };
        }),
      itemTotalCount: data.items_total_count ?? 0,
    }));
  });

  const getPhonesSecond = useRequest<PhoneCode[], IPhoneCodeState>(getPhoneCodesSecond, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      phoneCodes: data.result &&
        p.phoneCodes && [
          ...p.phoneCodes,
          ...data.result.map((item: PhoneCode) => {
            const newItem = { ...item };
            return {
              ...newItem,
              label: `+${newItem.phone_code}`,
              value: newItem.phone_code,
            };
          }),
        ],
    }));
  });

  const getPhones = async () => {
    await getPhonesFirst();
    await getPhonesSecond();
  };

  return useMemo(
    () => ({
      get isLoading() {
        return state.loading;
      },
      get phoneCodes() {
        return state.phoneCodes;
      },
      get itemTotalCount() {
        return state.itemTotalCount;
      },
      get error() {
        return state.requestError;
      },
      getPhones,
    }),
    [getPhonesSecond, getPhonesFirst, state.requestError, state.itemTotalCount, state.phoneCodes, state.loading], //  eslint-disable-line react-hooks/exhaustive-deps
  );
};
