import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { store } from '../../store';
import urlHome from '../../url/urlHome';
import styles from '../../components/Forms/FormLogin/LoginForm.module.scss';
import LoginForm from '../../components/Forms/FormLogin/LoginForm';
import Header from '../../components/Header';
import { getTerms } from '../../api/private/terms/terms';
import { accessToken } from '../../utils/tokens';

const Login = () => {
  const [requestError, setRequestError] = useState<string>();
  const history = useHistory();

  const {
    state: { authState },
  } = useContext(store);

  const handleLogin = useCallback(
    async (values) => {
      const { email, password } = values;
      setRequestError(undefined);
      authState.isLoading = true;
      await authState
        .login({ email, password })
        .then(async () => {
          authState.isLoading = false;
          await getTerms().then((res) => {
            if (!res.data?.result?.is_approved) {
              history.push('/terms');
              localStorage.setItem('termsId', res.data?.result?.id as string);
            } else {
              history.push(urlHome());
            }
          });
        })
        .catch((e: { response: { data: { error_content: React.SetStateAction<string | undefined> } } }) => {
          setRequestError(e.response.data.error_content);
        });
    },
    [authState, history],
  );

  useEffect(() => {
    if (authState.isAuthenticated && accessToken.get()) {
      history.push(urlHome());
    }
  }, [authState.isAuthenticated, history]);

  return (
    <div className="blockLogin">
      <Header />
      <div className="formWrapper">
        <div className={styles.formModal}>
          <LoginForm onSubmit={handleLogin} requestError={requestError} setRequestError={setRequestError} />
        </div>
      </div>
    </div>
  );
};

export default Login;
