import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import {
  urlOrgManagmentBilling,
  urlOrgManagmentDetail,
  urlOrgManagmentFees,
  urlOrgManagmentFinancialAccs,
  urlOrgManagmentProduct,
  urlOrgManagmentUsers,
} from '../../url/urlOrgManagementItem';
import styles from './TabsMenu.module.scss';

export interface MatchParams {
  id?: string | undefined;
  userId?: string | undefined;
}

const defaultProps = {
  id: ':id',
};

const TabsMenu = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const chekForIncludeRouteName = (route: string) => location.pathname.split('/').includes(route);
  const { id }: MatchParams = match.params;
  return (
    <div className={styles.tabs}>
      <div className={styles.linkWrapper}>
        <Link
          to={urlOrgManagmentDetail({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('details') ? styles.active : ''}`}
        >
          Details
        </Link>
        <Link
          to={urlOrgManagmentUsers({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('users') ? styles.active : ''}`}
        >
          Users
        </Link>
        <Link
          to={urlOrgManagmentProduct({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('product') ? styles.active : ''}`}
        >
          Product
        </Link>
        <Link
          to={urlOrgManagmentBilling({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('billing') ? styles.active : ''}`}
        >
          Billing
        </Link>
        <Link
          to={urlOrgManagmentFees({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('fees') ? styles.active : ''}`}
        >
          Fees
        </Link>
        <Link
          to={urlOrgManagmentFinancialAccs({ id })}
          className={`${styles.link} ${chekForIncludeRouteName('financial-accaunts') ? styles.active : ''}`}
        >
          Financial Accaunts
        </Link>
      </div>
    </div>
  );
};

TabsMenu.defaultProps = defaultProps;

export default TabsMenu;
