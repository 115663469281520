import jwt_decode from 'jwt-decode';

const ACCESS_TOKEN_NAME = process.env.ACCESS_TOKEN_STORAGE_NAME || 'access';
const REFRESH_TOKEN_NAME = process.env.REFRESH_TOKEN_STORAGE_NAME || 'refresh';

const accessToken = {
  get: () => localStorage.getItem(ACCESS_TOKEN_NAME),
  set: (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
  },
};

const refreshToken = {
  get: () => localStorage.getItem(REFRESH_TOKEN_NAME),
  set: (token: string) => localStorage.setItem(REFRESH_TOKEN_NAME, token),
};

const cleanupTokens = () => {
  localStorage.clear();
};

const isValid = (token: string | null): boolean => {
  if (!token) return false;

  const currentMoment = new Date().getTime();
  const { exp } = jwt_decode<{ exp: number }>(token);
  const tokenExpMoment = new Date(exp * 1000).getTime();

  return currentMoment < tokenExpMoment;
};

const getUserIdFromToken = (token: string | null) => {
  if (!token) return null;

  const { user_id } = jwt_decode<{ user_id: string }>(token);

  return user_id;
};

const getOpcoIdFromToken = (token: string | null) => {
  if (!token) return null;

  const { opco } = jwt_decode<{ opco: string[] }>(token);

  return opco[0];
};

export { accessToken, refreshToken, cleanupTokens, isValid, getUserIdFromToken, getOpcoIdFromToken };
