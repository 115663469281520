import React from 'react';
import styles from './NewAccount.module.scss';
import PageHeader from '../../components/PageHeader';
import Button, { BUTTON_VARIANTS } from '../../components/Button';

const NewAccount = () => (
  <>
    <PageHeader label="Accounts Overview" />
    <div className={styles.wrapper} />
    <div className={styles.blockInfo}>
      <div>
        <p className={styles.title}>Looks like you're visiting an empty page.</p>
        <p className={styles.createAccountTitle}>To create new accounts click below.</p>
      </div>
      <div className={styles.blockButton}>
        <Button variant={BUTTON_VARIANTS.LOG_IN}>New Account</Button>
      </div>
    </div>
  </>
);

export default NewAccount;
