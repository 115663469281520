import { UserType } from '../enums';

const Permission = {
  organizationManagement: 'organizationManagement',
  userManagement: 'userManagment',
};

const permissionsUserRoleMap = {
  [Permission.organizationManagement]: [UserType.OP_CO],
  [Permission.userManagement]: [UserType.ORGANIZATION],
};

const hasPermission = (permission: keyof typeof permissionsUserRoleMap, checkRoles: UserType[]) =>
  permissionsUserRoleMap[permission]?.some((role) => checkRoles.includes(role));

export { Permission, permissionsUserRoleMap, hasPermission };
