import { urlBuilder } from './builder';

const baseUsersUrl = urlBuilder(() => '/users/');

const userResetPasswordUrl = urlBuilder(() => `/reset-password`, true);

const userSetNewPasswordUrl = urlBuilder(
  ({ id = ':id', token = ':token' }) => `/users/${id}/reset-password/${token}`,
  true,
);

export { userResetPasswordUrl, userSetNewPasswordUrl };

export default baseUsersUrl;
