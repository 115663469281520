import React, { createContext, FunctionComponent } from 'react';
import useStore from './useStore';
import { initialState } from './state';

const store = createContext({ state: initialState });
const { Provider } = store;

const StateProvider: FunctionComponent = ({ children }) => {
  const state = useStore(initialState);

  return <Provider value={{ state }}>{children}</Provider>;
};

export { store, StateProvider };
