import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { store } from '../../../store';
import urlHome from '../../../url/urlHome';
import Button, { BUTTON_VARIANTS } from '../../Button';
import { cleanupTokens } from '../../../utils/tokens';
import styles from './ResetPassword.module.scss';
import { hideEmail } from '../../../utils/hideEmail';
import recoveryPassword from '../../../api/public/recoveryPassword';
import ErrorIcon from '../../Icons/ErrorIcon';
import Header from '../../Header';

interface IResetPassword {}

const FormResetPassword: FC<IResetPassword> = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const title = () => <h2 className={styles.title}>Reset your password</h2>;

  const history = useHistory();

  const {
    state: { authState },
  } = useContext(store);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target));
    setEmail(data.email as string);
    // eslint-disable-next-line no-console
    console.log('trying to reset password for email: ', data.email);
    setEmailSent(true);
  }, []);

  const handleResubmit = useCallback(
    () =>
      // eslint-disable-next-line no-console
      recoveryPassword({ email: email as string }),
    [email],
  );

  const handleGoLogin = useCallback(() => {
    history.push('/login');
    cleanupTokens();
  }, [history]);

  const handleReset = useCallback(() => {
    setEmailSent(false);
    setEmail('');
  }, []);

  const handlerEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const userEmail = e.currentTarget.value;
    setEmail(userEmail);
  };
  const sendEmail = useCallback(async () => {
    await recoveryPassword({ email })
      .then(() => {
        setEmailSent(true);
      })
      .catch((e) => {
        setError(e.response.data.error_content.email || e.response.data.error_content);
      });
  }, [email]);

  const hideErrorMessage = () => {
    setError('');
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push(urlHome());
    }
  }, [authState.isAuthenticated, history]);

  return (
    <div className="blockLogin">
      <Header />
      <div className="formWrapper">
        {!emailSent && (
          <div className={styles.RecoveryPassword_block}>
            {title()}
            <p className={styles.text}>Enter the email address associated with the account. </p>
            <form onSubmit={handleSubmit}>
              <input
                name="email"
                type="email"
                className={styles.input}
                value={email}
                onChange={handlerEmail}
                onFocus={hideErrorMessage}
              />
              {error && (
                <span className={styles.error_block}>
                  <ErrorIcon />
                  <p className={styles.error}>{error}</p>
                </span>
              )}
              <div className={styles.button_block}>
                <Button variant={BUTTON_VARIANTS.RETURN} onClick={handleGoLogin}>
                  Return to SignIn
                </Button>
                <Button variant={BUTTON_VARIANTS.RECOVERY} type="button" onClick={sendEmail}>
                  Recover Password
                </Button>
              </div>
            </form>
          </div>
        )}
        {emailSent && (
          <div className={styles.hide_password__block}>
            {title()}
            <span>
              To verify your identity, a link has been sent to your email{' '}
              <p className={styles.hide_password}>{hideEmail(email ?? '')}</p>
            </span>
            <p className={styles.text}>
              Didn't receive the email?{' '}
              <button type="button" onClick={handleResubmit} className={styles.button}>
                Send a new one
              </button>
            </p>
            <Button variant={BUTTON_VARIANTS.GO_BACK} onClick={handleReset}>
              Go Back
            </Button>
            <Button variant={BUTTON_VARIANTS.CONTINUE} onClick={handleGoLogin}>
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormResetPassword;
