import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Button, { ICON_POSITION } from '../../../components/Button';
import { ReactComponent as PlusSignIcon } from '../../../components/Icons/PlusSignIcon.svg';
import styles from './styles.module.scss';
import Loader from '../../../components/Loader';
import Table, { TOrdering } from '../../../components/Table';
import { TOrganization } from '../../../models';
import Paginator from '../../../components/Paginator';
import { ISelect } from '../../../interfaces/ISelect';
import { urlOrgManagmentCreate } from '../../../url/urlOrgManagementItem';
import { LIMIT } from '../../../const/various';
import { mkColumns } from './const';
import { store } from '../../../store';

const List = () => {
  const history = useHistory();
  const {
    state: { organizationState },
  } = useContext(store);
  const { isExact } = useRouteMatch();
  const [orderBy, setOrderBy] = useState<TOrdering>();
  const [page, setPage] = useState(1);

  const getOrgsList = useCallback(async () => {
    if (!isExact) return;
    await organizationState.getOpcoOrganizations({
      ordering: orderBy,
      limit: LIMIT,
      offset: (page - 1) * LIMIT,
    });
  }, [isExact, orderBy, organizationState, page]);

  const columnsWithActions = useMemo(() => {
    const handler = (option: ISelect | null) => {
      if (option?.value) {
        history.push(option?.value);
      }
    };

    return mkColumns(handler);
  }, [history]);

  const handleAddNew = useCallback(() => {
    history.push(urlOrgManagmentCreate({ id: 'create' }));
  }, [history]);

  useEffect(() => {
    getOrgsList();
  }, [getOrgsList]);

  return (
    <>
      <PageHeader
        label="Organization Management"
        renderControls={() => (
          <div>
            <Button onClick={handleAddNew} iconPosition={ICON_POSITION.RIGHT} icon={<PlusSignIcon />}>
              Add new org
            </Button>
          </div>
        )}
      />
      <div className={styles.pageWrapper}>
        <Loader transparent enabled={organizationState.loading}>
          <Table<TOrganization>
            orderBy={orderBy}
            onOrderChange={setOrderBy}
            items={organizationState.organizationsList ?? []}
            columns={columnsWithActions}
          />
          <Paginator page={page} setPage={setPage} total={organizationState.organizationsListTotalCount} />
        </Loader>
      </div>
    </>
  );
};

export default List;
