import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Table, { TOrdering } from '../../components/Table';
import { mkColumnsUser } from './const';
import styles from './AllUsersOrgManagement.module.scss';
import Loader from '../../components/Loader';
import { useUserOpcoState } from '../../store/states/UserOpcoState';
import Paginator from '../../components/Paginator';
import { MatchParams } from '../../components/Tabs/TabsMenu';
import { LIMIT } from '../../const/various';

const AllUserOrgManagement = () => {
  const userOpCoState = useUserOpcoState();
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;
  const [orderBy, setOrderBy] = useState<TOrdering>();
  const [page, setPage] = useState(1);
  const getUserList = useCallback(async () => {
    await userOpCoState.getOpcoUser({ id }, { ordering: orderBy, limit: LIMIT, offset: (page - 1) * LIMIT });
  }, [userOpCoState, id, orderBy, page]);

  useEffect(() => {
    getUserList();
  }, [page, orderBy]); //  eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <Loader transparent enabled={userOpCoState.isLoading}>
        <Table
          columns={mkColumnsUser(id as string)}
          items={userOpCoState.userOpcoList ?? []}
          orderBy={orderBy}
          onOrderChange={setOrderBy}
        />
        <Paginator page={page} setPage={setPage} total={userOpCoState.itemTotalCount} />
      </Loader>
    </div>
  );
};

export default AllUserOrgManagement;
