import AuthState from './states/AuthState';
import OrganizationState from './states/OrganizationState';
import UserState from './states/UserState';

export interface GlobalState {
  authState: AuthState;
  organizationState: OrganizationState;
  userState: UserState;
}

export const initialState: GlobalState = {
  authState: new AuthState(),
  organizationState: new OrganizationState(),
  userState: new UserState(),
};
