import React from 'react';

const OrgManagmentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="none" viewBox="0 0 18 17">
    <path
      fill="#F5F6F7"
      d="M9.217 3.692V0H0v16.615h18V3.692H9.217zM3.687 14.77H1.84v-1.846h1.846v1.846zm0-3.692H1.84V9.23h1.846v1.846zm0-3.692H1.84V5.538h1.846v1.847zm0-3.693H1.84V1.846h1.846v1.846zM7.374 14.77H5.527v-1.846h1.847v1.846zm0-3.692H5.527V9.23h1.847v1.846zm0-3.692H5.527V5.538h1.847v1.847zm0-3.693H5.527V1.846h1.847v1.846zm8.783 11.077h-6.94v-1.846h1.846v-1.846H9.217V9.23h1.846V7.385H9.217V5.538h6.94v9.231zM14.53 7.385h-1.846V9.23h1.846V7.385zm0 3.692h-1.846v1.846h1.846v-1.846z"
    />
  </svg>
);
export default OrgManagmentIcon;
