import { Link } from 'react-router-dom';
import { TSystemUser } from '../../../models';
import { ITableColumn } from '../../../components/Table';
import Status, { LABEL_VARIANTS } from '../../../components/Status';
import { capitalise } from '../../../utils/capitalize';
import Button, { BUTTON_VARIANTS } from '../../../components/Button';
import { formateDate } from '../../../utils/dateUtils/dateUtils';
import { urlSystemUserDetails, urlSystemUserEdit } from '../../../url/urlSystemUsers';

const mkColumnsSystemUser = (): ITableColumn<TSystemUser>[] => [
  {
    header: 'Full Name',
    order: { name: 'name' },
    renderer: ({ name, id }) => <Link to={urlSystemUserDetails({ id })}>{name}</Link>,
  },
  {
    header: 'Email Address',
    order: { name: 'email' },
    renderer: ({ email }) => <span>{email}</span>,
  },
  {
    header: 'Date Created',
    order: { name: 'date_created' },
    renderer: ({ date_created }) => <span>{formateDate(date_created)}</span>,
  },
  {
    header: 'Last Modified',
    order: { name: 'date_modified' },
    renderer: ({ date_modified }) => <span>{formateDate(date_modified)}</span>,
  },
  {
    header: 'Role',
    order: { name: 'role' },
    renderer: ({ role }) => <span>{capitalise(role)}</span>,
  },
  {
    header: 'Status',
    order: { name: 'status' },
    renderer: ({ status }) => <Status variant={capitalise(status) as LABEL_VARIANTS} />,
  },
  {
    header: '',
    renderer: ({ id }) => (
      <Button path={urlSystemUserEdit({ id })} variant={BUTTON_VARIANTS.EDIT}>
        edit
      </Button>
    ),
  },
];

export { mkColumnsSystemUser };
