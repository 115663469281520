import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MatchParams } from '../../Tabs/TabsMenu';
import { CREATOR_NEW_ORG, CREATOR_NEW_ORG_SECONDARY } from '../FormData/FormData';
import Form from '../form';
import styles from './NewOrganizationForm.module.scss';
import { useOrganizationState } from '../../../store/states/OrganizationState';
import { useIndustryState } from '../../../store/states/IndustryState';
import Loader from '../../Loader';
import { useStatesState } from '../../../store/states/States';
import flattenObject from '../../../utils/flattenObject';
import useEffectOnce from '../../../utils/customHooks/useEffectOnce';
import urlOrgManagement from '../../../url/urlOrgManagement';
import { usePhoneCodesState } from '../../../store/states/PhoneCodes.State';
import { TOrganization } from '../../../models';
import { urlOrgManagmentDetail } from '../../../url/urlOrgManagementItem';
import { validationSchemaOrg } from '../../../utils/patterns/patterns';

interface Iprops {
  userInfo: TOrganization | null;
  editMode: boolean;
}

const NewOrganizationForm = ({ userInfo, editMode }: Iprops) => {
  const industriesState = useIndustryState();
  const statesState = useStatesState();
  const phoneCodesState = usePhoneCodesState();
  const organizationsState = useOrganizationState();
  const history = useHistory();
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;

  const fieldsItems = CREATOR_NEW_ORG.map((item) => {
    const newItem = { ...item };
    if (newItem.name === 'industry') {
      // @ts-ignore
      newItem.options = industriesState.industryList;
    }
    if (newItem.name === 'state') {
      // @ts-ignore
      newItem.options = statesState.stateList;
    }
    if (newItem.name === 'phone') {
      // @ts-ignore
      newItem.inputs[0].options = phoneCodesState.phoneCodes;
    }
    if (!editMode && newItem.name === 'tin') {
      // @ts-ignore
      newItem.inputs[0].value = '';
      // @ts-ignore
      newItem.inputs[1].value = '';
    }
    if (!editMode && newItem.name === 'phone') {
      // @ts-ignore
      newItem.inputs[0].value = '';
      // @ts-ignore
      newItem.inputs[1].value = '';
    }
    return newItem;
  });
  const orgUsersFields = userInfo
    ? fieldsItems.map((item) => {
        const newItem = { ...item };
        Object.keys(userInfo).forEach((userItem) => {
          if (newItem.name === userItem) {
            // @ts-ignore
            newItem.value = userInfo[userItem];
          }
          if (newItem.name === 'tin') {
            const tinArr = userInfo.tin?.split('-');
            // @ts-ignore
            // eslint-disable-next-line
            newItem.inputs.forEach((input, idi) => (input.value = tinArr[idi]));
          }
          if (newItem.name === 'phone' && userInfo.phone) {
            const phoneCode = `${userInfo.phone.slice(0, userInfo.phone.length - 10)}`;
            const phoneFirst = userInfo.phone.slice(phoneCode.length);
            const phoneArr = [`+${phoneCode}`, phoneFirst];
            // @ts-ignore
            // eslint-disable-next-line
            newItem.inputs?.forEach((input, idi) => (input.value = phoneArr[idi]));
          }
        });
        return newItem;
      })
    : fieldsItems;

  const extraOrgUsersFields = userInfo
    ? CREATOR_NEW_ORG_SECONDARY.map((item) => {
        const newItem = { ...item };
        Object.keys(userInfo).forEach((userItem) => {
          if (newItem.name === userItem) {
            // @ts-ignore
            newItem.value = userInfo[userItem];
          }
        });
        return newItem;
      })
    : CREATOR_NEW_ORG_SECONDARY;

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      const stateOption = statesState?.stateList?.find((item) => item.name === values.state);
      const payload = {
        name: values.name,
        site: values.site,
        duns: values.duns,
        legal_name: values.legal_name,
        country: values.country,
        address: {
          state: stateOption?.id,
          city: values.city,
          code: values.zipCode,
          address_1: values.address_1,
          address_2: values.address_2,
        },
        tin: `${values.companyTaxIdFirst}-${values.companyTaxIdSecond}` || '',
        phone: parseFloat(`${values.companyPhoneOne}${values.companyPhoneTwo}`),
        industry: values.industry,
        current_status: values.current_status,
      };
      if (!editMode) {
        await organizationsState
          ?.createOpcoOrganization({ payload })
          .then(() => {
            history.push(urlOrgManagement());
          })
          .catch((e) => {
            setErrors(flattenObject(e.response.data.error_content));
          });
      }
      if (editMode) {
        await organizationsState
          .updateOpcoOrganization({ payload, id })
          .then(() => {
            history.push(urlOrgManagmentDetail({ id }));
          })
          .catch((e) => setErrors(flattenObject(e.response.data.error_content)));
      }
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationsState, history],
  );

  const getIndustries = useCallback(async () => {
    await industriesState.getIndustries();
  }, [industriesState]);

  const getStates = useCallback(async () => {
    await statesState.getStates();
  }, [statesState]);

  const getPhoneCodes = useCallback(async () => {
    await phoneCodesState.getPhones();
  }, [phoneCodesState]);

  useEffectOnce(async () => {
    await Promise.all([getIndustries(), getStates(), getPhoneCodes()]);
  });
  return (
    <Loader transparent enabled={industriesState.isLoading && statesState.isLoading}>
      <Form
        validationScheme={validationSchemaOrg}
        fields={orgUsersFields}
        extraFields={extraOrgUsersFields}
        editMode={editMode}
        userInfo={userInfo}
        className={styles.formWidth}
        onSubmit={handleSubmit}
      />
    </Loader>
  );
};

export default NewOrganizationForm;
