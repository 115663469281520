import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from '../../OrgManagement/List/styles.module.scss';
import Loader from '../../../components/Loader';
import Table, { TOrdering } from '../../../components/Table';
import { TSystemUser } from '../../../models';
import { mkColumnsSystemUser } from './const';
import { LIMIT } from '../../../const/various';
import { useSystemUsersState } from '../../../store/states/SystemUser.State';
import Paginator from '../../../components/Paginator';
import { store } from '../../../store';
import useEffectOnce from '../../../utils/customHooks/useEffectOnce';
import { accessToken, getOpcoIdFromToken } from '../../../utils/tokens';

const TableSystemUser = () => {
  const systemUsersState = useSystemUsersState();
  const {
    state: { authState },
  } = useContext(store);
  const { opcoId } = authState;
  const [orderBy, setOrderBy] = useState<TOrdering>();
  const [page, setPage] = useState(1);
  const getUserList = useCallback(async () => {
    await systemUsersState.getSystemUsers({
      opcoId: opcoId ?? getOpcoIdFromToken(accessToken.get()),
      ordering: orderBy,
      limit: LIMIT,
      offset: (page - 1) * LIMIT,
    });
  }, [systemUsersState, opcoId, orderBy, page]);

  useEffectOnce(async () => {
    await getUserList();
  });

  useEffect(() => {
    getUserList();
  }, [page, orderBy, opcoId]); //  eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={styles.pageWrapper}>
      <Loader transparent enabled={systemUsersState.isLoading}>
        <Table<TSystemUser>
          columns={mkColumnsSystemUser()}
          items={systemUsersState.systemUserList ?? []}
          orderBy={orderBy}
          onOrderChange={setOrderBy}
        />
        <Paginator page={page} setPage={setPage} total={systemUsersState.systemUserTotalCount} />
      </Loader>
    </div>
  );
};

export default TableSystemUser;
