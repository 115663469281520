/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

export function loading(
  target: Object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<any>,
): TypedPropertyDescriptor<any> {
  const originalMethod = descriptor.value;

  descriptor.value = async function Wrapper(...args: any[]) {
    const parentObject = this as any;
    parentObject.loading = true;
    parentObject.__storeContext.postAction();

    const result = await originalMethod.apply(parentObject, args).catch((e: Error) => {
      parentObject.loading = false;
      parentObject.__storeContext.postAction();
      throw e;
    });

    parentObject.loading = false;
    parentObject.__storeContext.postAction();

    return result;
  };

  return descriptor;
}
