import { Link } from 'react-router-dom';
import { urlOrgManagmentDetail, urlOrgManagmentUsers } from '../../../url/urlOrgManagementItem';
import { ISelectComponent } from '../../../components/Select';
import { ITableColumn } from '../../../components/Table';
import { TOrganization } from '../../../models';
import { formateDate } from '../../../utils/dateUtils/dateUtils';
import Select from '../../../components/Select/Select';
import Status, { LABEL_VARIANTS } from '../../../components/Status';

const mkOptions = (id: string) => [
  { value: urlOrgManagmentUsers({ id }), label: 'Manage Users' },
  { value: urlOrgManagmentDetail({ id }), label: 'Manage Credentialing Docs' },
  { value: urlOrgManagmentDetail({ id }), label: 'Manage Portfolios' },
];

const mkColumns = (actionHandler: ISelectComponent['onChange']): ITableColumn<TOrganization>[] => [
  {
    header: 'Organization Name',
    order: { name: 'name' },
    renderer: ({ name, id }) => <Link to={urlOrgManagmentDetail({ id })}>{name}</Link>,
  },
  {
    header: 'Date Created',
    order: { name: 'date_created' },
    renderer: ({ date_created }) => <span>{formateDate(date_created)}</span>,
  },
  {
    header: 'Last Modified',
    order: { name: 'date_modified' },
    renderer: ({ date_modified }) => <span>{formateDate(date_modified)}</span>,
  },
  {
    header: 'Last Modified By',
    order: { name: 'last_modified_by' },
    renderer: ({ last_modified_by }) => <span>{last_modified_by.email}</span>,
  },
  {
    header: 'Status',
    order: { name: 'current_status' },
    renderer: ({ current_status }) => <Status variant={current_status as LABEL_VARIANTS} />,
  },
  {
    header: '',
    renderer: ({ id }) => <Select placeholder="Actions" onChange={actionHandler} options={mkOptions(id)} />,
  },
];

export { mkColumns };
