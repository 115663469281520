export enum BUTTON_VARIANTS {
  SAVE = 'save',
  CANCEL = 'cancel',
  EDIT = 'edit',
  DEFAULT = 'default',
  GO_BACK = 'go back',
  GO_LOGIN = 'go login',
  RECOVERY = 'recovery',
  RETURN = 'return',
  CONTINUE = 'continue',
  LOG_OUT = 'log out',
  DISABLED = 'disabled',
  LOG_IN = 'log in',
  FORGOT_PASSWORD = 'forgot password',
}

export enum ICON_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}
