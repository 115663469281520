import { IInput } from '../Models/FormModels';
import FieldInput from '../Fields/FieldInput';
import FieldSelect from '../Fields/FieldSelect';

export const renderElementByType = (elementProps: IInput) => {
  switch (elementProps.type) {
    case 'select':
      return (
        // @ts-ignore
        <FieldSelect {...elementProps} />
      );
    case 'radio':
      return <div>Radio</div>;
    case 'text':
      // @ts-ignore
      return <FieldInput {...elementProps} />;
    default:
      return <div />;
  }
};
