/* eslint-disable @typescript-eslint/no-explicit-any */

import { TApiResponse } from '../../../common';
import instance from '../instance';

export interface PhoneCode {
  id: string;
  name: string;
  phone_code: string;
}

export interface Filters {
  offset: string;
}

export const getPhoneCodesFirst = async (): Promise<TApiResponse<PhoneCode[]>> =>
  instance.get('/locations/countries/phone-codes/');
export const getPhoneCodesSecond = async (params: Filters): Promise<TApiResponse<PhoneCode[]>> => {
  const requestParams = { params: { offset: 100 } };
  return instance.get(`/locations/countries/phone-codes/`, requestParams);
};
