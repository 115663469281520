import { useMemo, useState } from 'react';
import { TSystemUser } from '../../models';
import { getSystemUsers as getUsers, getSystemUserDetail as getDetails } from '../../api/private/sistemUsers/get';
import { updateSystemUserDetail } from '../../api/private/sistemUsers/put';
import { createSystemUser as createUser } from '../../api/private/opco/organizations/post';
import useRequest from '../../utils/customHooks/useRequest';

export interface ISystemUsersState {
  loading: boolean;
  systemUserList: TSystemUser[] | null;
  systemUser: TSystemUser | null;
  itemTotalCount?: number;
}

export const useSystemUsersState = () => {
  const [state, setState] = useState<ISystemUsersState>({
    loading: false,
    systemUserList: null,
    itemTotalCount: undefined,
    systemUser: null,
  });

  const getSystemUsers = useRequest<TSystemUser[], ISystemUsersState>(getUsers, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      systemUserList: data.result,
      itemTotalCount: data.items_total_count ?? 0,
    }));
  });

  const getSystemUserDetail = useRequest<TSystemUser, ISystemUsersState>(getDetails, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      systemUser: data.result,
    }));
  });

  const createSystemUser = useRequest<TSystemUser, ISystemUsersState>(createUser, setState);

  const updateSystemUser = useRequest<TSystemUser, ISystemUsersState>(updateSystemUserDetail, setState);

  return useMemo(
    () => ({
      get isLoading() {
        return state.loading;
      },
      get systemUserList() {
        return state.systemUserList;
      },
      get systemUser() {
        return state.systemUser;
      },
      get systemUserTotalCount() {
        return state.itemTotalCount;
      },
      getSystemUsers,
      getSystemUserDetail,
      createSystemUser,
      updateSystemUser,
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.loading,
      state.systemUserList,
      state.itemTotalCount,
      getSystemUsers,
      updateSystemUser,
      createSystemUser,
      state.systemUser,
    ],
  );
};
