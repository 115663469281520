import instance from './instance';
import { accessToken, refreshToken } from '../../utils/tokens';

interface IRefreshTokensRequest {
  userId: string | null;
}

const refreshTokens = async ({ userId }: IRefreshTokensRequest): Promise<string | null> => {
  if (!userId) {
    return null;
  }

  const { data: res } = await instance.post(`/users/${userId}/refresh-tokens/`, {
    payload: {
      refresh: refreshToken.get(),
    },
  });

  if (res.ok) {
    refreshToken.set(res.result.refresh);
    accessToken.set(res.result.access);
  }

  return res.result.access;
};

export default refreshTokens;
