import { TOrganization, TSystemUser } from '../../../../models';
import instance from '../../instance';
import { TApiResponse } from '../../../../common';

export type ICreateOrgPayload = Pick<
  TOrganization,
  'name' | 'site' | 'duns' | 'address' | 'tin' | 'phone' | 'industry' | 'current_status'
>;
export type ICreateSystemUserPayload = Pick<TSystemUser, 'name' | 'email' | 'role' | 'status'>;

const create = ({ payload }: { payload: ICreateOrgPayload }): Promise<TApiResponse<TOrganization>> =>
  instance.post('/opco/organizations/', { payload });

const createOpcoUser = ({
  id,
  payload,
}: {
  id: string;
  payload: ICreateOrgPayload;
}): Promise<TApiResponse<TOrganization>> => instance.post(`/organizations/${id}/users`, { payload });

const createSystemUser = ({
  id,
  payload,
}: {
  id: string;
  payload: ICreateSystemUserPayload;
}): Promise<TApiResponse<TSystemUser>> => instance.post(`/opco/${id}/users`, { payload });

export { create, createOpcoUser, createSystemUser };
