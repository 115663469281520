import React from 'react';
import styles from './Header.module.scss';
import HeaderLogo from '../../assets/HeaderLogo';

const Header = () => (
  <div className={styles.wrapper}>
    <div className={styles.block_logo}>
      <HeaderLogo />
    </div>
  </div>
);

export default Header;
