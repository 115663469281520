import React, { useCallback, useState } from 'react';
import styles from './Logout.module.scss';
import AccountIcon from '../Icons/AccountIcon';
import LogoutModal from './LogoutModal';

interface ILogout {}

const Logout: React.FC<ILogout> = () => {
  const [isOpened, setIsOpened] = useState(false);

  const handleToggleModal = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  return (
    <div className={styles.wrapper}>
      <div
        role="button"
        className={styles.button}
        onClick={handleToggleModal}
        tabIndex={0}
        onKeyPress={handleToggleModal}
      >
        <div className={styles.Icon}>
          <AccountIcon />
        </div>
      </div>
      {isOpened && <LogoutModal onClose={handleToggleModal} />}
    </div>
  );
};

export default Logout;
