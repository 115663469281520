import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import ToggleSwitcher, { TOGGLE_SWITCHER_VARIANT } from '../../ToggleSwitcher';
import styles from './NewProductForm.module.scss';
import RadioButton from '../../RadioButton';
import Button, { BUTTON_VARIANTS } from '../../Button';
import { generateRandomId } from '../../../utils/genereteRandomId';
import { useOrganizationState } from '../../../store/states/OrganizationState';
import { MatchParams } from '../../Tabs/TabsMenu';
import { urlOrgManagmentDetail } from '../../../url/urlOrgManagementItem';
import useEffectOnce from '../../../utils/customHooks/useEffectOnce';
import { TAddress, TOrganization } from '../../../models';

const NewProductForm = () => {
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;
  const organizationState = useOrganizationState();
  const stateId = organizationState.organizationsItem?.address?.state.id;
  const { name, site, duns, legal_name, country, tin, phone, industry, current_status } = useMemo(
    () => organizationState.organizationsItem ?? ({} as TOrganization),
    [organizationState.organizationsItem],
  );
  const { code, city, address_1, address_2 } = useMemo(
    () => organizationState.organizationsItem?.address ?? ({} as TAddress),
    [organizationState.organizationsItem?.address],
  );

  const getOpcoDetails = useCallback(async () => {
    await organizationState.getOpcoOrganizationsItem({ id });
  }, [organizationState, id]);

  const history = useHistory();
  const cancelHandler = () => {
    history.goBack();
  };

  const initialValueForm = {
    is_collector: '',
    is_originator: '',
  };
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handlerSubmit = async (values: any) => {
    if (values.is_originator === '') {
      // eslint-disable-next-line no-param-reassign
      values.is_originator = 'false';
    }
    if (values.is_collector === '') {
      // eslint-disable-next-line no-param-reassign
      values.is_collector = 'false';
    }
    const payload = {
      name,
      site,
      duns,
      legal_name,
      country,
      address: {
        state: stateId,
        city,
        code,
        address_1,
        address_2,
      },
      tin,
      phone,
      industry,
      current_status,
      is_collector: values.is_collector,
      is_originator: values.is_originator,
    };
    await organizationState
      .updateOpcoOrganization({ payload, id })
      .then(() => history.push(urlOrgManagmentDetail({ id })));
  };

  useEffectOnce(async () => {
    await getOpcoDetails();
  });

  return (
    <Formik initialValues={initialValueForm} onSubmit={handlerSubmit}>
      {({ values, handleSubmit, handleChange, errors }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <div className={styles.wrapperBlock}>
              <div className={styles.wrapperDebtCollection}>
                <div className={styles.blockTitle}>
                  <span className={styles.title}>DEBT COLLECTIONS</span>
                </div>
                <div className={styles.toggleBlock}>
                  <ToggleSwitcher name="debt collections" variant={TOGGLE_SWITCHER_VARIANT.YELLOW} />
                </div>
              </div>
              <div className={styles.radioButtonBlock}>
                <div className={styles.radioButton}>
                  <div className={styles.radio}>
                    <span className={styles.roleTitle}>Is Collector</span>
                    <div className={styles.radioBlock}>
                      <RadioButton
                        variant={[
                          { value: 'true', title: 'Yes', name: 'is_collector', id: generateRandomId() },
                          { value: 'false', title: 'No', name: 'is_collector', id: generateRandomId() },
                        ]}
                      />
                    </div>
                  </div>
                  <div className={styles.radio}>
                    <span className={styles.roleTitle}>Is Originator</span>
                    <div className={styles.radioBlock}>
                      <RadioButton
                        variant={[
                          { value: 'true', title: 'Yes', name: 'is_originator', id: generateRandomId() },
                          { value: 'false', title: 'No', name: 'is_originator', id: generateRandomId() },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.link}>
                  <NavLink className={styles.linkTo} to="/">
                    Manage Portfolio
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={styles.debtCollectionBlock}>
              <span>Per Debt Collection Fees:*</span>
              <div className={styles.debtCollection}>
                <>
                  <span className={styles.badge}> $ </span>
                  <input type="text" placeholder=" Amount" disabled={true} />
                </>
                <>
                  <span className={styles.badge}> % </span>
                  <input type="text" placeholder=" Percentage" disabled={true} />
                </>
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.wrapperDebtCollection}>
              <div className={styles.blockTitle}>
                <span className={styles.title}>QUICKBOOKS</span>
              </div>
              <div className={styles.toggleBlock}>
                <ToggleSwitcher name="quickbooks" variant={TOGGLE_SWITCHER_VARIANT.YELLOW} />
              </div>
            </div>
            <div />
            <div className={styles.buttonBlock}>
              <Button variant={BUTTON_VARIANTS.CANCEL} onClick={cancelHandler}>
                Cancel
              </Button>
              <Button variant={BUTTON_VARIANTS.SAVE} type="submit">
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default NewProductForm;
