// options

const NewUserOption = [
  { value: 'Active', label: 'Active' },
  { value: 'Open', label: 'Open' },
  { value: 'Closed', label: 'Closed' },
  { value: 'Archived', label: 'Archived' },
  { value: 'For Sale', label: 'For Sale' },
];
const NewUserOptionRole = [
  { value: 'Org_user', label: 'Org_user' },
  { value: 'Org_admin', label: 'Org_admin' },
];

const NewSystemUserOption = [
  { value: 'Active', label: 'Active' },
  { value: 'Blocked', label: 'Blocked' },
];

const NewSystemUserRoleOption = [{ value: 'Opco', label: 'Opco' }];

// formItems

const COMPANY_NAME = {
  label: 'Company Name',
  name: 'name',
  value: '',
  placeholder: 'Company Name',
  type: 'text',
  size: 'l',
  required: true,
};

const COMPANY_ID = {
  label: 'GUID: ',
  name: 'id',
  value: '',
  placeholder: '',
  type: 'text',
  size: 'l',
  disabled: true,
  required: false,
};

const INDUSTRY = {
  label: 'Industry:',
  name: 'industry',
  placeholder: 'Select industry',
  value: '',
  type: 'select',
  size: 'l',
  required: true,
  options: [
    { value: 'Legal Dispute', label: 'Legal Dispute' },
    { value: 'industry2', label: 'industry2' },
    { value: 'industry1', label: 'industry1' },
  ],
};

const COMPANY_ADDRESS_FIRST = {
  label: 'Company Address Line 1:',
  name: 'address_1',
  value: '',
  placeholder: 'Company Address Line',
  type: 'text',
  size: 'l',
  required: true,
};

const COMPANY_ADDRESS_SECOND = {
  label: 'Company Address Line 2:',
  name: 'address_2',
  value: '',
  placeholder: 'Company Address Line',
  type: 'text',
  size: 'l',
  required: false,
};

const COMPANY_LEGAL_NAME = {
  label: 'Company Legal Name:',
  name: 'legal_name',
  value: '',
  placeholder: 'Company Legal Name',
  type: 'text',
  size: 'l',
  required: true,
};

const COMPANY_WEBSITE = {
  label: 'Company Website:',
  name: 'site',
  value: '',
  placeholder: 'Company Website',
  type: 'text',
  size: 'l',
  required: false,
};

const COMPANY_TAX_ID = {
  label: 'Company tax ID Number:',
  name: 'tin',
  value: '',
  required: true,
  size: 'l',
  inputs: [
    {
      name: 'companyTaxIdFirst',
      value: '',
      placeholder: '',
      type: 'text',
      size: 'l',
      maxLength: '2',
    },
    {
      name: 'companyTaxIdSecond',
      value: '',
      placeholder: '',
      type: 'text',
      size: 'xxl',
      maxLength: '7',
    },
  ],
};

const COMPANY_PHONE_NUMBER = {
  label: 'Company Phone Number:',
  name: 'phone',
  required: true,
  value: '',
  size: 'l',
  inputs: [
    {
      name: 'companyPhoneOne',
      label: 'CompanyPhoneOne',
      value: '',
      placeholder: '+1',
      maxLength: '',
      size: 'xxm',
      type: 'select',
      options: [{ value: '+1', label: '+1' }],
    },
    {
      name: 'companyPhoneTwo',
      label: 'CompanyPhoneTwo',
      value: '',
      placeholder: '',
      maxLength: '10',
      size: 'xxxl',
      type: 'text',
    },
  ],
};

const CITY = {
  label: 'City:',
  name: 'city',
  value: '',
  placeholder: 'City',
  type: 'text',
  size: 'l',
  required: true,
};

const STATE = {
  label: 'State:',
  name: 'state',
  value: '',
  placeholder: 'State',
  type: 'select',
  size: 'sm',
  required: true,
  options: [{ label: '', value: '' }],
};

const COMPANY_DUNS = {
  label: 'Company DUNS:',
  name: 'duns',
  value: '',
  placeholder: 'Company DUNS',
  type: 'text',
  size: 'l',
  required: true,
};

const COUNTRY = {
  label: 'Country:',
  name: 'country',
  placeholder: 'United States of America',
  value: 'United States of America',
  type: 'select',
  size: 'l',
  required: true,
  options: [{ value: 'United States of America', label: 'United States of America' }],
};

const ZIP_CODE = {
  label: 'Zip Code:',
  name: 'zipCode',
  value: '',
  placeholder: 'Zip',
  type: 'text',
  size: 'sm',
  required: true,
  maxLength: '6',
};

// const STATUS = {
//   label: 'Status:',
//   name: 'status',
//   value: null,
//   placeholder: 'Select status',
//   type: 'select',
//   size: 'l',
//   required: true,
//   options: [
//     { value: 'active', label: 'active' },
//     { value: 'blocked', label: 'blocked' },
//   ],
// };

const CURRENT_STATUS = {
  label: 'Status:',
  name: 'current_status',
  value: '',
  placeholder: 'Select status',
  type: 'select',
  size: 'l',
  required: true,
  options: [
    { value: 'Registered', label: 'Registered' },
    { value: 'Active', label: 'Active' },
  ],
};

const CREATE_NEW_USER_STATUS = {
  label: 'Status:',
  name: 'status',
  value: '',
  placeholder: 'Select status',
  type: 'select',
  size: 'l',
  required: true,
  options: NewUserOption,
};

const CREATE_NEW_USER_ROLE = {
  label: 'Role:',
  name: 'role',
  placeholder: 'Role',
  type: 'select',
  value: '',
  size: 'xxl',
  required: true,
  options: NewUserOptionRole,
};

// const REFERENCE_NUMBER = {
//   label: 'Reference Number:',
//   name: 'referenceNumber',
//   value: '',
//   placeholder: 'Reference Number',
//   type: 'text',
//   size: 'l',
//   required: false,
//   maxLength: '6',
// };

const EMAIL_ADDRESS = {
  label: 'Email Address:',
  name: 'email',
  placeholder: 'Email',
  value: '',
  type: 'text',
  size: 'l',
  required: false,
};

const FULL_NAME = {
  label: 'Full Name:',
  name: 'name',
  placeholder: 'Full Name',
  value: '',
  type: 'text',
  size: 'xxl',
  required: true,
};

// const ROLE = {
//   label: 'Role:',
//   name: 'role',
//   placeholder: 'Role ...',
//   type: 'select',
//   value: null,
//   size: 'xxl',
//   required: true,
//   options: [
//     { value: 'org_user', label: 'org_user' },
//     { value: 'admin', label: 'admin' },
//     { value: 'consumer', label: 'consumer' },
//     { value: 'organization', label: 'organization' },
//     { value: 'regulator', label: 'regulator' },
//     { value: 'opco', label: 'opco' },
//   ],
// };

const CREATE_NEW_SYSTEM_USER_STATUS = {
  label: 'Status:',
  name: 'status',
  value: '',
  placeholder: 'Select status',
  type: 'select',
  size: 'l',
  required: true,
  options: NewSystemUserOption,
};

const CREATE_NEW_SYSTEM_USER_ROLE = {
  label: 'Role:',
  name: 'role',
  placeholder: 'Select Role',
  type: 'select',
  value: '',
  size: 'xxl',
  required: true,
  options: NewSystemUserRoleOption,
};

export const CREATOR_NEW_ORG = [
  COMPANY_NAME,
  INDUSTRY,
  COMPANY_ADDRESS_FIRST,
  COMPANY_LEGAL_NAME,
  COMPANY_WEBSITE,
  COMPANY_ADDRESS_SECOND,
  COMPANY_TAX_ID,
  COMPANY_PHONE_NUMBER,
  CITY,
  STATE,
  COMPANY_DUNS,
  COUNTRY,
  ZIP_CODE,
];

export const CREATE_NEW_USER = [
  FULL_NAME,
  { ...EMAIL_ADDRESS, required: true },
  CREATE_NEW_USER_ROLE,
  CREATE_NEW_USER_STATUS,
].map((field) => ({
  ...field,
  size: 'xxl',
}));

export const CREATOR_NEW_ORG_SECONDARY = [CURRENT_STATUS, COMPANY_ID];
export const CREATE_NEW_SYSTEM_USER = [
  { ...FULL_NAME, required: true },
  { ...EMAIL_ADDRESS, required: true },
  CREATE_NEW_SYSTEM_USER_ROLE,
  CREATE_NEW_SYSTEM_USER_STATUS,
].map((field) => ({ ...field, size: 'xxl' }));
