import { useState } from 'react';
import getStates, { IState } from '../../api/private/states/states';

export interface IStatesState {
  loading: boolean;
  stateList: IState[] | null;
  itemTotalCount?: number;
  requestError: string | null;
}

export const useStatesState = () => {
  const [state, setState] = useState<IStatesState>({
    loading: false,
    stateList: null,
    itemTotalCount: undefined,
    requestError: null,
  });
  return {
    get isLoading() {
      return state.loading;
    },
    get stateList() {
      return state.stateList;
    },
    get industryListTotal() {
      return state.itemTotalCount;
    },
    get error() {
      return state.requestError;
    },
    getStates: async (): Promise<void> => {
      setState((p) => ({ ...p, loading: true, requestError: null }));

      const { data: res } = await getStates()
        .catch((e) => e.response)
        .finally(() => {
          setState((p) => ({ ...p, loading: false }));
        });

      if (res.ok || res.status_code === 200) {
        const resultArray = res.result.map((item: IState) => {
          const newItem = { ...item };
          return {
            ...newItem,
            label: newItem.name,
            value: newItem.id,
          };
        });
        setState((p) => ({ ...p, stateList: resultArray, itemTotalCount: res.items_total_count }));
      } else {
        setState((p) => ({ ...p, requestError: res.error_content }));
      }
    },
  };
};
