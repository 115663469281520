import { useState } from 'react';
import getIndustries, { IIndustry } from '../../api/private/industries/industries';

export interface IIndustrieState {
  loading: boolean;
  industryList: IIndustry[] | null;
  itemTotalCount?: number;
  requestError: string | null;
}

export const useIndustryState = () => {
  const [state, setState] = useState<IIndustrieState>({
    loading: false,
    industryList: null,
    itemTotalCount: undefined,
    requestError: null,
  });
  return {
    get isLoading() {
      return state.loading;
    },
    get industryList() {
      return state.industryList;
    },
    get industryListTotal() {
      return state.itemTotalCount;
    },
    get error() {
      return state.requestError;
    },
    getIndustries: async (): Promise<void> => {
      setState((p) => ({ ...p, loading: true, requestError: null }));

      const { data: res } = await getIndustries()
        .catch((e) => e.response)
        .finally(() => {
          setState((p) => ({ ...p, loading: false }));
        });

      if (res.ok || res.status_code === 200) {
        const resultArray = res.result.map((item: IIndustry) => {
          const newItem = { ...item };
          return {
            ...newItem,
            label: newItem.title,
            value: newItem.title,
          };
        });
        setState((p) => ({ ...p, industryList: resultArray, itemTotalCount: res.items_total_count }));
      } else {
        setState((p) => ({ ...p, requestError: res.error_content }));
      }
    },
  };
};
