import * as Yup from 'yup';
import { emailRegex, passwordRegex, phoneRegex } from '../../const/various';

const initialValues = {
  email: '',
  password: '',
};

const initialValuesSetNewPassword = {
  password: '',
  passwordConfirmation: '',
};

const validationSchemaLogin = Yup.object({
  email: Yup.string()
    .required()
    .trim('email cannot contain a space')
    .strict(true)
    .matches(emailRegex, 'Please, enter a valid email address.'),
  password: Yup.string().required(),
});

const validationPhone = Yup.object({
  phone: Yup.string().matches(phoneRegex, 'Phone must be a number'),
});

const validationSchemaOrg = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  site: Yup.string(),
  duns: Yup.string()
    .required('This field is required')
    .matches(phoneRegex, 'DUNS must be a number')
    .length(9, 'DUNS should be no more than 9 numbers'),
  legal_name: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  zipCode: Yup.string().required('This field is required').matches(phoneRegex, 'Zip code must be a number'),
  address_1: Yup.string().required('This field is required'),
  address_2: Yup.string(),
  tin: Yup.object().shape({
    companyTaxIdFirst: Yup.string()
      .matches(phoneRegex, 'Tin must be a number')
      .length(2, 'Should contain more numbers')
      .required('This field is required'),
    companyTaxIdSecond: Yup.string()
      .matches(phoneRegex, 'Tin must be a number')
      .length(7, 'Should contain more numbers')
      .required('This field is required'),
  }),
  phone: Yup.object().shape({
    companyPhoneOne: Yup.string().required('This field is required'),
    companyPhoneTwo: Yup.string()
      .matches(phoneRegex, 'Phone number must be a number')
      .required('This field is required')
      .min(10, 'Phone number must be a 10 numbers'),
  }),
  industry: Yup.string().required('This field is required'),
  current_status: Yup.string().required('This field is required'),
});

const validationSchemaSystemUser = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required').matches(emailRegex, 'Please enter a valid email address'),
  status: Yup.string().required('This field is required'),
  role: Yup.string().required('This field is required'),
});

const validationSchemaSetNewPassword = Yup.object({
  password: Yup.string()
    .matches(passwordRegex, 'Your password does not satisfy the criteria.')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

const validationSchemaQuickCreateAccount = Yup.object().shape({
  account_number: Yup.string().required('Oops, this is required.'),
  account_state: Yup.string().required('Oops, this is required'),
  current_balance: Yup.string().required('Oops, this is required').matches(phoneRegex, 'Oops, number only, please'),
  portfolio_name: Yup.string().required('Oops, this is required'),
  account_status: Yup.string().required('Oops, this is required'),
  account_type: Yup.string().required('Oops, this is required'),
  current_balance_date: Yup.date()
    .required('Oops, this is required')
    .max(new Date(), 'Oops, date cannot be in the future'),
  open_date: Yup.date().max(new Date(), 'Oops, date cannot be in the future'),
  email: Yup.string().matches(emailRegex, 'Please enter a valid email address'),
  name: Yup.string(),
  ssn: Yup.string().matches(phoneRegex, 'Oops, number only, please'),
  phone: Yup.object().shape({
    companyPhoneOne: Yup.string(),
    companyPhoneTwo: Yup.string()
      .matches(phoneRegex, 'Phone number must be a number')
      .min(10, 'Phone number must be a 10 numbers'),
  }),
});

export {
  initialValues,
  validationSchemaLogin,
  validationPhone,
  validationSchemaOrg,
  validationSchemaSystemUser,
  validationSchemaSetNewPassword,
  initialValuesSetNewPassword,
  validationSchemaQuickCreateAccount,
};
