import React from 'react';
import { Route, Switch } from 'react-router-dom';
import urlSystemUsers, { urlSystemUserCreate, urlSystemUserDetails, urlSystemUserEdit } from '../../url/urlSystemUsers';
import SystemUserList from './SystemUserList';
import SystemUserItem from './SystemUserCreate';

const SystemUserManagment = () => (
  <Switch>
    <Route exact path={urlSystemUsers()} component={SystemUserList} />
    <Route path={urlSystemUserCreate()} component={SystemUserItem} />
    <Route path={urlSystemUserDetails()} component={SystemUserItem} />
    <Route path={urlSystemUserEdit()} component={SystemUserItem} />
  </Switch>
);

export default SystemUserManagment;
