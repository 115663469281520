import instance from '../private/instance';
import { TApiResponse } from '../../common';

interface IRecoveryPassword {
  email: string;
}

interface IRecoveryPasswordResponse {
  ok: boolean;
}

const recoveryPassword = async ({ email }: IRecoveryPassword): Promise<TApiResponse<IRecoveryPasswordResponse>> =>
  instance.post('/users/recovery-password/', {
    payload: {
      email,
    },
  });

export default recoveryPassword;
