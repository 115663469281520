import axios from 'axios';
import { BASE_API_URL } from '../const';
import { accessToken, getUserIdFromToken, isValid, refreshToken, cleanupTokens } from '../../utils/tokens';
import refreshTokens from '../public/refreshTokens';

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 5000,
});

let authInterceptor: number | null = null;

export const setAuthHeader = (token: string) => {
  if (authInterceptor !== null) {
    instance.interceptors.request.eject(authInterceptor);
  }

  authInterceptor = instance.interceptors.request.use((request) => {
    request.headers = {
      ...request.headers,
      Authorization: token || accessToken.get(),
    };

    return request;
  });
};

instance.interceptors.request.use((config) => ({
  ...config,
  url: !config.url?.endsWith('/') ? `${config.url}/` : config.url,
}));

instance.interceptors.request.use((request) => {
  request.headers = {
    ...request.headers,
    Authorization: accessToken.get(),
  };

  return request;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      try {
        const currentRefreshToken = refreshToken.get();

        if (!isValid(currentRefreshToken)) {
          cleanupTokens();
          throw new Error('Refresh Token is expired');
        }

        const currentUserId = getUserIdFromToken(currentRefreshToken);

        if (!currentUserId) {
          cleanupTokens();
          throw new Error('No user_id in refresh token!');
        }

        const token = await refreshTokens({ userId: currentUserId });

        if (token) {
          setAuthHeader(token);
          return await instance(originalRequest);
        }
      } catch (e) {
        window.location.href = `${window.location.origin}/login`;
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
