import React, { useCallback, useContext, useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import { store } from '../../store';
import useEffectOnce from '../../utils/customHooks/useEffectOnce';
import NewAccount from '../CreateNewOrgAccount';

const Home = () => {
  const {
    state: { authState, userState },
  } = useContext(store);
  const [home, setHome] = useState(true);

  const handleGetUserById = useCallback(async () => {
    const { userId } = authState;
    if (userId) {
      await userState.getUserById({ id: userId });
    }
  }, [authState, userState]);

  const pageHeader = () => (
    <>
      <PageHeader
        label="Home"
        renderControls={() => (
          <div>
            <button type="button" onClick={handleGetUserById}>
              {userState.loading ? 'Loading' : 'getUserById'}
            </button>
          </div>
        )}
      />
      <div>
        <code>{JSON.stringify({ userData: userState.lastRequestedUser })}</code>
      </div>
    </>
  );

  const newAccount = () => <NewAccount />;

  useEffectOnce(async () => {
    await handleGetUserById();
  });
  useEffect(() => {
    if (authState.opcoId) {
      setHome(true);
    } else {
      setHome(false);
    }
  }, [authState.opcoId]);

  return <>{home ? pageHeader() : newAccount()}</>;
};

export default Home;
