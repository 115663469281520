import { Field, useField } from 'formik';
import React from 'react';
import { IInput } from '../../Models/FormModels';
import styles from './FieldInput.module.scss';

const FieldInput = ({ ...props }: IInput) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const { maxLength } = props;
  return (
    <Field name={field.name}>
      {() => (
        <div className={styles.fieldInput}>
          <input
            {...field}
            disabled={props.disabled}
            placeholder={props.disabled ? field.value : props.placeholder}
            maxLength={parseFloat(maxLength?.toString() || '100')}
          />
          {touched && error && <div className={styles.error}>{error || ''}</div>}
        </div>
      )}
    </Field>
  );
};

export default FieldInput;
