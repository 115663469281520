import { ITable } from './const';
import { mkNextOrdering } from './utils';
import SortSwitches from './SortSwitchers';
import styles from './Table.module.scss';

const Table = <T,>({
  columns,
  items,
  onItemClick,
  customKeyProp,
  hideIfEmpty,
  orderBy,
  onOrderChange,
  disableScroll,
  tableHeight,
  ...props
}: ITable<T>) => {
  if (!items?.length) {
    return <div>There is no users yet</div>;
  }
  return (
    <div {...props} className={styles.table}>
      <div className={styles.tableHeader}>
        {columns.map(({ header, order, columnWidth, customKey }) => {
          const headerClassName = order?.name.toString().toLowerCase().split(' ').join('');

          return (
            <div
              className={`${styles.tableHeaderLinkWrapper} ${styles[headerClassName || '']}`}
              key={customKey || header.toString()}
              style={{ width: columnWidth }}
            >
              <span className={styles.tableHeaderLink}>{header}</span>
              {order && onOrderChange && (
                <SortSwitches
                  active={order.name === orderBy?.name}
                  order={orderBy?.direction}
                  onClick={() => onOrderChange(mkNextOrdering(order.name, orderBy))}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.tableContent}>
        {items.map((item, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className={styles.tableContentWrapper}
            key={customKeyProp ? (item[customKeyProp] as unknown as string) : `${JSON.stringify(item)}_${index}`}
            onClick={onItemClick ? () => onItemClick(item) : () => {}}
          >
            {customKeyProp}
            {columns.map(({ header, order, columnWidth, renderer, customKey }) => {
              const headerClassName = order?.name.toString().toLowerCase().split(' ').join('');

              return (
                <div
                  className={`${styles.tableContentLink} ${styles[headerClassName || '']}`}
                  key={`col_${customKey || header.toString()}`}
                  style={{ width: columnWidth }}
                >
                  {renderer(item)}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
