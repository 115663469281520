import { useMemo, useState } from 'react';
import { TOrganization } from '../../models';
import { getList as getOpcoOrgList, getItem, RequestParams } from '../../api/private/opco/organizations/get';
import { create as createOpcoOrg, createOpcoUser } from '../../api/private/opco/organizations/post';
import { updateOpcoOrganization as update, updateOpcoUser } from '../../api/private/opco/organizations/put';
import useRequest from '../../utils/customHooks/useRequest';
import { loading } from '../../utils/decorators/loading';

export interface IOrganizationState {
  loading: boolean;
  organizationsList: TOrganization[] | null;
  organizationsItem: TOrganization | null;
  organizationsListTotalCount?: number;
}

export const useOrganizationState = () => {
  const [state, setState] = useState<IOrganizationState>({
    loading: false,
    organizationsList: null,
    organizationsItem: null,
    organizationsListTotalCount: undefined,
  });

  const getOpcoOrganizations = useRequest<TOrganization[], IOrganizationState>(getOpcoOrgList, setState, ({ data }) => {
    setState((p) => ({
      ...p,
      organizationsList: data.result,
      organizationsListTotalCount: data.items_total_count ?? 0,
    }));
  });

  const getOpcoOrganizationsItem = useRequest<TOrganization, IOrganizationState>(getItem, setState, ({ data }) => {
    // @ts-ignore
    setState((p) => ({
      ...p,
      organizationsItem: {
        address_1: data.result?.address?.address_1,
        address_2: data.result?.address?.address_2,
        zipCode: data.result?.address?.code,
        city: data.result?.address?.city,
        state: data.result?.address?.state.name,
        ...data.result,
      },
    }));
  });

  const createOpcoOrganization = useRequest<TOrganization, IOrganizationState>(createOpcoOrg, setState);
  const createOpcoOrganizationUser = useRequest<TOrganization, IOrganizationState>(createOpcoUser, setState);
  const updateOpcoOrganizationUser = useRequest<TOrganization, IOrganizationState>(updateOpcoUser, setState);
  const updateOpcoOrganization = useRequest<TOrganization, IOrganizationState>(update, setState);

  return useMemo(
    () => ({
      get isLoading() {
        return state.loading;
      },
      get organizationsList() {
        return state.organizationsList;
      },
      get organizationsItem() {
        return state.organizationsItem;
      },
      get organizationsTotalCount() {
        return state.organizationsListTotalCount;
      },
      getOpcoOrganizations,
      createOpcoOrganization,
      createOpcoOrganizationUser,
      getOpcoOrganizationsItem,
      updateOpcoOrganizationUser,
      updateOpcoOrganization,
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createOpcoOrganization,
      getOpcoOrganizationsItem,
      getOpcoOrganizations,
      state.loading,
      state.organizationsList,
      state.organizationsListTotalCount,
      createOpcoOrganizationUser,
      updateOpcoOrganizationUser,
      updateOpcoOrganization,
    ],
  );
};

export default class OrganizationState {
  loading: boolean = false;

  organizationsList: TOrganization[] | null = null;

  organizationsItem: TOrganization | null = null;

  organizationsListTotalCount?: number = undefined;

  @loading
  public async getOpcoOrganizations(params: RequestParams) {
    const { data } = await getOpcoOrgList(params);

    this.organizationsList = data.result;
    this.organizationsListTotalCount = data.items_total_count ?? 0;
  }
}
