import React, { useCallback, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import TabsMenu, { MatchParams } from '../../../components/Tabs/TabsMenu';
import PageHeader from '../../../components/PageHeader';
import NewUserForm from '../../../components/Forms/NewUserForm/NewUserForm';
import Loader from '../../../components/Loader';
import { useUserOpcoState } from '../../../store/states/UserOpcoState';
import { useOrganizationState } from '../../../store/states/OrganizationState';

const OrgManagementNewUser = () => {
  const userOpcoState = useUserOpcoState();
  const location = useLocation();
  const match = useRouteMatch();
  const organizationState = useOrganizationState();
  const { id, userId }: MatchParams = match.params;
  const ifDetails = match.path.split('/').includes('details');
  const ifEdit = match.path.split('/').includes('edit');
  const title = organizationState.organizationsItem?.name;
  const getUserDetails = useCallback(async () => {
    await userOpcoState.getOpcoUserDetails({
      id: id || '',
      userId: userId || '',
    });
  }, [userOpcoState, id]); //  eslint-disable-line react-hooks/exhaustive-deps

  const getOpcoDetails = useCallback(async () => {
    await organizationState.getOpcoOrganizationsItem({ id });
  }, [organizationState, id]);

  useEffect(() => {
    getUserDetails();
    getOpcoDetails();
  }, [location, userId]); //  eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Loader transparent enabled={userOpcoState.isLoading || organizationState.isLoading}>
        <PageHeader label={`${title || ''} | Manage user / create`} />
        <TabsMenu />
        {ifDetails || ifEdit ? (
          <NewUserForm userInfo={userOpcoState.userOpcoListItem} editMode={ifEdit} />
        ) : (
          <NewUserForm userInfo={null} editMode={false} />
        )}
      </Loader>
    </>
  );
};

export default OrgManagementNewUser;
