import loginRequest, { ILoginRequest } from '../../api/public/login';
import { refreshToken, accessToken, isValid, getUserIdFromToken, getOpcoIdFromToken } from '../../utils/tokens';
import logout from '../../api/public/logout';

type TAuth = { email: string; userId: string | null; opco: string | null };

export default class AuthState {
  isLoading: boolean = false;

  isAuthenticated: boolean = isValid(accessToken.get());

  opcoId: string | null = null;

  userId: string | null = null;

  auth: TAuth = { userId: getUserIdFromToken(accessToken.get()), opco: getOpcoIdFromToken(accessToken.get()) } as TAuth;

  login = async ({ email, password }: ILoginRequest) => {
    const { data } = await loginRequest({ email, password });

    this.auth = {
      ...this.auth,
      email,
      userId: data.result?.user_id as string,
    };
    this.userId = getUserIdFromToken(data?.result?.access as string | null);
    this.opcoId = getOpcoIdFromToken(data?.result?.access as string | null);
    this.isAuthenticated = isValid(data.result?.access as string);
    accessToken.set(data.result?.access as string);
    refreshToken.set(data.result?.refresh as string);
  };

  logOut = async () => {
    await logout();
    this.userId = null;
    this.opcoId = null;
    this.isAuthenticated = isValid(null);
    this.isLoading = false;
  };
}
