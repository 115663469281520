/* eslint-disable @typescript-eslint/no-explicit-any */

import { TApiResponse } from '../../../common';
import instance from '../instance';

export interface IState {
  id: string;
  name: string;
}

const getStates = async (): Promise<TApiResponse<IState>> => instance.get('/locations/states/');

export default getStates;
