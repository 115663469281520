import { useContext, useMemo } from 'react';
import MenuItem from './MenuItem';
import { MENU_CATEGORIES_DATA, MENU_CATEGORIES_ITEMS_MAP, MENU_CATEGORIES_ROLE, MENU_ITEMS_DATA } from './conts';
import styles from './Menu.module.scss';
import TitleIcon from '../Icons/TitleIcon';
import { store } from '../../store';

const Menu: React.FC = () => {
  const {
    state: { userState },
  } = useContext(store);
  const categories = useMemo(() => {
    const userType = userState.lastRequestedUser?.type;
    return userType ? MENU_CATEGORIES_ROLE[userType] : [];
  }, [userState.lastRequestedUser?.type]);

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.logoWrapper}>
        <TitleIcon />
        {Object.values(categories).map(
          (menuCategory) =>
            !!MENU_CATEGORIES_ITEMS_MAP[menuCategory].length && (
              <div key={menuCategory} className={styles.menuCategory}>
                <span className={styles.categoryTitle}>{MENU_CATEGORIES_DATA[menuCategory].label}</span>
                {MENU_CATEGORIES_ITEMS_MAP[menuCategory].map((menuItem) => (
                  <div key={menuItem}>
                    <MenuItem {...MENU_ITEMS_DATA[menuItem]} />
                  </div>
                ))}
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default Menu;
