import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import TabsMenu, { MatchParams } from '../../../components/Tabs/TabsMenu';
import PageHeader from '../../../components/PageHeader';
import NewOrganizationForm from '../../../components/Forms/NewOrganizationForm/NewOrganizationForm';
import { useOrganizationState } from '../../../store/states/OrganizationState';
import Loader from '../../../components/Loader';

const OrgManagementItem = () => {
  const organizationState = useOrganizationState();
  const location = useLocation();
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;
  const ifDetails = match.path.split('/').includes('details');
  const ifEdit = match.path.split('/').includes('edit');
  const ifCreate = match.path.split('/').includes('create');
  const title = organizationState.organizationsItem?.name;
  const getOpcoDetails = useCallback(async () => {
    await organizationState.getOpcoOrganizationsItem({ id });
  }, [organizationState, id]);

  const label = useMemo(() => {
    let labelBase = 'ORG MANAGEMENT ITEM';
    const labelStart = title || '';
    if (ifEdit || ifDetails) {
      labelBase = `${labelStart} | ${labelBase}`;
    }
    return labelBase;
  }, [title, ifDetails, ifEdit]);

  useEffect(() => {
    if (ifDetails || ifEdit) {
      getOpcoDetails();
    }
  }, [location]); //  eslint-disable-line react-hooks/exhaustive-deps

  if (organizationState.isLoading) {
    return null;
  }

  return (
    <>
      <Loader transparent enabled={organizationState.isLoading}>
        <PageHeader label={label} />
        <>{!ifCreate && <TabsMenu />}</>
        {ifDetails || ifEdit ? (
          <NewOrganizationForm userInfo={organizationState.organizationsItem} editMode={ifEdit} />
        ) : (
          <NewOrganizationForm userInfo={null} editMode={false} />
        )}
      </Loader>
    </>
  );
};

export default OrgManagementItem;
