import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './pages/Login';
import theme from './styles/theme';
import './style.scss';
import { userSetNewPasswordUrl } from './url/urlUsers';
import SetNewPassword from './pages/SetNewPassword';
import FormResetPassword from './components/Forms/FormResetPassword';
import urlRecoveryPassword from './url/urlRecoveryPassword';
import Terms from './pages/Terms';
import { StateProvider } from './store';

ReactDOM.render(
  <StateProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/app" component={App} />
          <Route path="/login" component={Login} />
          <Route path={urlRecoveryPassword()} component={FormResetPassword} />
          <Route path={userSetNewPasswordUrl()} component={SetNewPassword} />
          <Route path="/terms" component={Terms} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </ThemeProvider>
  </StateProvider>,
  document.getElementById('root'),
);

reportWebVitals();
