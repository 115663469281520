import React from 'react';

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="none" viewBox="0 0 18 18">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 10h8V0H0v10zm0 8h8v-6H0v6zm10 0h8V8h-8v10zm0-18v6h8V0h-8z"
      clipRule="evenodd"
      opacity=".9"
    />
  </svg>
);
export default HomeIcon;
