import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { IForm, IInput, IValues } from './Models/FormModels';
import styles from './Form.module.scss';
import Button from '../Button/Button';
import { renderElementByType } from './FormUtils/renderElementByType';
import { BUTTON_VARIANTS } from '../Button';

const Form = ({ fields, validationScheme, extraFields, className, onSubmit, userInfo, editMode }: IForm) => {
  const history = useHistory();
  const location = useLocation();
  const linkToEdit = location.pathname.replace(/details$/, 'edit');
  const cancelHandler = () => {
    history.goBack();
  };

  const handleEdit = () => {
    if (userInfo) {
      history.push(linkToEdit);
    }
  };

  const fieldsValues = fields.reduce((acc, cur: IInput) => {
    if (cur.inputs) {
      // @ts-ignore
      let curObj = {};
      cur.inputs.forEach((curItem) => {
        curObj = {
          ...curObj,
          [curItem.name]: curItem.value,
        };
      });
      return { ...acc, ...curObj };
    }
    return { ...acc, [cur.name]: cur.value };
  }, {});

  const extraFieldValues =
    extraFields &&
    extraFields.reduce((acc, cur: IInput) => {
      if (cur.inputs) {
        return { ...acc, [cur.name]: cur.inputs };
      }
      return { ...acc, [cur.name]: cur.value };
    }, {});

  return (
    <div className={`${styles.formWrapper}`}>
      <Formik
        enableReinitialize
        validate={(values: IValues) => {
          // @ts-ignore
          // eslint-disable-next-line
          values.phone = {
            companyPhoneOne: values.companyPhoneOne,
            companyPhoneTwo: values.companyPhoneTwo,
          };
          // @ts-ignore
          // eslint-disable-next-line
          values.tin = {
            companyTaxIdFirst: values.companyTaxIdFirst,
            companyTaxIdSecond: values.companyTaxIdSecond,
          };
        }}
        validationSchema={validationScheme}
        initialValues={{ ...fieldsValues, ...extraFieldValues }}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <form className={`${styles.formInner} newOrgForm`}>
            <div className={`${styles.form} ${className}`}>
              {fields.map((item: IInput) =>
                item.inputs ? (
                  <label key={item.name} className={`${styles.label} ${styles[item.size || '']}`} htmlFor={item.name}>
                    <span className={styles.labelText}>
                      {item.label}
                      {item.required && <span> *</span>}
                    </span>
                    <div
                      className={
                        item.name === 'phone' || item.name === 'tin' ? styles.labelWrapperPhone : styles.labelWrapper
                      }
                    >
                      <div
                        className={
                          item.name === 'phone' || item.name === 'tin' ? styles.labelInnerPhone : styles.labelInner
                        }
                      >
                        {item.inputs?.map((inputItem) => (
                          <div
                            key={inputItem.name}
                            className={`${
                              inputItem.name === 'companyPhoneOne' || inputItem.name === 'companyTaxIdFirst'
                                ? styles.inputItemWrapperPhoneOne
                                : styles.inputItemWrapper
                            } ${styles[inputItem.size || '']}`}
                          >
                            {userInfo && !editMode ? (
                              // @ts-ignore
                              <span className={styles.userInfo}>{inputItem.value || 'N/A'}</span>
                            ) : (
                              // @ts-ignore
                              renderElementByType({ ...inputItem, ...formikProps })
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    {
                      // @ts-ignore
                      formikProps.errors[item.name] && (
                        // @ts-ignore
                        <div className={styles.error}>{Object.values(formikProps.errors[item.name])[0]}</div>
                      )
                    }
                  </label>
                ) : (
                  <label key={item.name} htmlFor={item.name} className={`${styles.label} ${styles[item.size || '']}`}>
                    <span className={styles.labelText}>
                      {item.label}
                      {!userInfo && item.required && <span> *</span>}
                    </span>
                    {userInfo && !editMode ? (
                      // @ts-ignore
                      <span className={styles.userInfo}>{userInfo[item.name] || 'N/A'}</span>
                    ) : (
                      // @ts-ignore
                      renderElementByType({ ...item, ...formikProps })
                    )}
                  </label>
                ),
              )}
              {extraFields && extraFields.length >= 1 && (
                <div className={`${styles.secondaryWrapper}`}>
                  {extraFields.map((extra) => (
                    <label
                      key={extra.name}
                      htmlFor={extra.name}
                      className={`${styles.label} ${styles[extra.size || '']}`}
                    >
                      <span className={styles.labelText}>
                        {extra.label}
                        {extra.required && <span> *</span>}
                      </span>
                      {userInfo && !editMode ? (
                        // @ts-ignore
                        <span className={styles.userInfo}>{userInfo[extra.name] || 'N/A'}</span>
                      ) : (
                        // @ts-ignore
                        renderElementByType({ ...extra, ...formikProps })
                      )}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.controls}>
              <Button onClick={cancelHandler} variant={BUTTON_VARIANTS.CANCEL}>
                Cancel
              </Button>
              {userInfo && !editMode ? (
                <Button onClick={handleEdit} variant={BUTTON_VARIANTS.SAVE}>
                  Edit
                </Button>
              ) : (
                <Button onClick={formikProps.handleSubmit} variant={BUTTON_VARIANTS.SAVE}>
                  Save
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Form;
