import React from 'react';
import { LABEL_VARIANTS } from './const';
import styles from './Status.module.scss';

interface IStatus {
  variant: LABEL_VARIANTS;
}
const CLASS_VARIANTS = {
  [LABEL_VARIANTS.ACTIVE]: styles.lampActive,
  [LABEL_VARIANTS.PENDING]: styles.lampPending,
  [LABEL_VARIANTS.REGISTERED]: styles.lampRegistered,
  [LABEL_VARIANTS.SUSPENDED]: styles.lampSuspended,
  [LABEL_VARIANTS.CLOSE]: styles.lampClose,
  [LABEL_VARIANTS.DEFAULT]: styles.lampClose,
};

const Statuses: React.FC<IStatus> = ({ variant }) => (
  <div className={styles.wrapper}>
    <span className={`${styles.defaultVariant} ${CLASS_VARIANTS[variant]}`} />
    <span className={styles.labelStyle}>{variant}</span>
  </div>
);

Statuses.defaultProps = {
  variant: LABEL_VARIANTS.DEFAULT,
};
export default Statuses;
