import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import NewSystemUserForm from '../../../components/Forms/NewSystemUser/NewSystemUserForm';
import { useSystemUsersState } from '../../../store/states/SystemUser.State';
import { MatchParams } from '../../../components/Tabs/TabsMenu';
import Loader from '../../../components/Loader';
import { store } from '../../../store';
import { accessToken, getOpcoIdFromToken } from '../../../utils/tokens';

const SystemUserItem = () => {
  const systemUsersState = useSystemUsersState();
  const {
    state: { authState },
  } = useContext(store);
  const location = useLocation();
  const opcoId = authState.opcoId || getOpcoIdFromToken(accessToken.get());
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;
  const ifDetails = match.path.split('/').includes('details');
  const ifEdit = match.path.split('/').includes('edit');
  const getUserDetails = useCallback(async () => {
    await systemUsersState.getSystemUserDetail({ opcoId, id });
  }, [systemUsersState, opcoId, id]);

  const label = useMemo(() => {
    const labelBase = 'CrediVault User | ';
    let labelEnding = 'Create';
    if (ifDetails || ifEdit) {
      labelEnding = `${systemUsersState.systemUser?.name || ''}  ${ifDetails ? ' / Details' : ' / Edit'}`;
    }
    return `${labelBase}${labelEnding}`;
  }, [ifDetails, ifEdit, systemUsersState.systemUser?.name]);

  useEffect(() => {
    if (ifDetails || ifEdit) {
      getUserDetails();
    }
  }, [location]); //  eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Loader transparent enabled={systemUsersState.isLoading}>
        <PageHeader label={label} />
        <NewSystemUserForm userInfo={ifDetails || ifEdit ? systemUsersState.systemUser : null} editMode={ifEdit} />
      </Loader>
    </>
  );
};

export default SystemUserItem;
