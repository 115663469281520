import { Route, Switch } from 'react-router-dom';
import urlOrgManagement from '../../url/urlOrgManagement';
import {
  urlOrgManagmentCreate,
  urlOrgManagmentDetail,
  urlOrgManagmentEdit,
  urlOrgManagmentProduct,
  urlOrgManagmentUserDetails,
  urlOrgManagmentUserEdit,
  urlOrgManagmentUsers,
  urlOrgManagmentUsersNew,
} from '../../url/urlOrgManagementItem';
import OrgManagementItem from './Item/Item';
import List from './List';
import OrgManagementNewUser from './NewUser/NewUser';
import AllUsersOrgManagementItem from '../OrgManagementAllUsers/AllUsersOrgManagementItem';
import OrgManagmentProductCreate from '../OrgManagmentProduct/OrgManagmentProductCreate';

const OrgManagement = () => (
  <Switch>
    <Route exact path={urlOrgManagement()} component={List} />
    <Route path={urlOrgManagmentDetail()} component={OrgManagementItem} />
    <Route path={urlOrgManagmentCreate()} component={OrgManagementItem} />
    <Route path={urlOrgManagmentEdit()} component={OrgManagementItem} />
    <Route exact path={urlOrgManagmentUsers()} component={AllUsersOrgManagementItem} />
    <Route path={urlOrgManagmentUsersNew()} component={OrgManagementNewUser} />
    <Route path={urlOrgManagmentUserDetails()} component={OrgManagementNewUser} />
    <Route path={urlOrgManagmentUserEdit()} component={OrgManagementNewUser} />
    <Route path={urlOrgManagmentProduct()} component={OrgManagmentProductCreate} />
  </Switch>
);

export default OrgManagement;
