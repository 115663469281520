import instance from './instance';
import { TApiResponse } from '../../common';

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface ILoginResponse {
  access: string;
  refresh: string;
  user_id: string;
}

const login = async ({ email, password }: ILoginRequest): Promise<TApiResponse<ILoginResponse>> =>
  instance.post('/users/login/', {
    payload: {
      email,
      password,
    },
  });

export default login;
