import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import TabsMenu, { MatchParams } from '../../components/Tabs/TabsMenu';
import AllUserOrgManagement from './AllUsersOrgManagement';
import Button, { ICON_POSITION } from '../../components/Button';
import { ReactComponent as PlusSignIcon } from '../../components/Icons/PlusSignIcon.svg';
import SelectComponent from '../../components/Select';
import styles from './AllUsersOrgManagement.module.scss';
import { urlOrgManagmentUsersNew } from '../../url/urlOrgManagementItem';
import { OpcoUserSelect } from '../../interfaces/ISelect';
import { useOrganizationState } from '../../store/states/OrganizationState';
import useEffectOnce from '../../utils/customHooks/useEffectOnce';

const OrgManagementAllUsers = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const organizationState = useOrganizationState();
  const { id }: MatchParams = match.params;
  const title = organizationState.organizationsItem?.name;

  const addNewUserHandler = useCallback(() => {
    history.push(urlOrgManagmentUsersNew({ id }));
  }, [history, id]);

  const getOpcoDetails = useCallback(async () => {
    await organizationState.getOpcoOrganizationsItem({ id });
  }, [organizationState, id]);

  useEffectOnce(async () => {
    await getOpcoDetails();
  });

  return (
    <>
      <PageHeader
        label={`${title || ''} | Manage Users`}
        renderControls={() => (
          <div className={styles.headerBlock}>
            <div className={styles.selectWrapper}>
              <SelectComponent
                placeholder="All User"
                onChange={() => {}}
                options={OpcoUserSelect}
                className="OpcoUserSelect"
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button onClick={addNewUserHandler} iconPosition={ICON_POSITION.RIGHT} icon={<PlusSignIcon />}>
                Add New User
              </Button>
            </div>
          </div>
        )}
      />
      <TabsMenu />
      <AllUserOrgManagement />
    </>
  );
};

export default OrgManagementAllUsers;
