import { TOrdering } from './const';
import { Order } from './SortSwitchers/SortSwitchers';

const mkNextOrdering = (name: string, currentOrder?: TOrdering): TOrdering | undefined => {
  if (name !== currentOrder?.name) {
    return { name, direction: Order.ASC };
  }

  return currentOrder.direction === Order.ASC ? { name, direction: Order.DESC } : undefined;
};

export { mkNextOrdering };
