import React from 'react';

const TitleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="45" fill="none" viewBox="0 0 200 45">
    <g fill="#fff" clipPath="url(#clip0)">
      <path d="M20.336.145c-5.88.943-11.79 5.904-14.4 12.121-1.634 3.926-1.949 5.401-1.917 9.892 0 5.4.691 8.133 3.49 13.345.943 1.759 5.47 5.998 7.545 7.034 6.76 3.391 14.21 2.324 19.712-2.826 3.239-3.015 5.408-6.688 6.666-11.273.785-2.858.785-9.766 0-12.403-2.296-7.662-6.446-12.498-12.765-15.01-2.295-.88-5.816-1.256-8.331-.88zM27.41 14.59c.125 2.826-.372 3.322.817 3.674.817.575.786.314 2.358-1.13 1.76-1.633 2.598-1.99 2.787-.922.094.377-.382 1.047-1.545 2.209-1.812.97-2.105 2.125-2.01 3.569.115 1.77.642 1.706.988 1.958.283.22.692.502.849.659.189.126.754.534 1.32.848 1.132.66 1.415 1.162 1.164 1.884-.22.502-1.54.66-1.824.188-.094-.157-.817-.66-1.603-1.13l-1.667-.942-1.634.973c-.786.66-.217.754-.374 3.768l-.155 3.01-.618.155-.74-.155v-3.01c0-1.852-.341-3.478-.341-3.478 0-.44-1.016-.522-1.236-.773-.378-.377-.429.156-2.472 2.008-2.547 2.324-3.244 2.243-3.59 1.298-.095-.69 0-.719 3.898-4.299.723-.659.786-.942.66-2.418l-.126-1.695-2.609-1.696c-1.446-.942-2.672-1.884-2.767-2.104-.251-.785.157-1.476.944-1.476.628 0 3.112 1.414 3.866 2.199.095.094.504.376.944.659.723.471.929.628 1.872 0l.957-.816V11.136l.88.094.85.094.157 3.266z" />
      <path
        fillRule="evenodd"
        d="M14.158 42.877c-6.852-3.3-11.686-11.293-11.686-20.63s4.833-17.33 11.684-20.63C6.229 2.497 0 11.397 0 22.247s6.23 19.75 14.158 20.63zM62.766 18.486c-.165-1.448-1.264-2.966-2.61-3.613-.714-.337-.755-.337-5.04-.323-2.678 0-4.477.07-4.738.155-1.333.492-2.486 1.729-2.885 3.12-.302 1.04-.288 8.111.014 9.208.371 1.377 1.456 2.572 2.76 3.05.4.14 1.566.183 4.863.197h4.34l.838-.422c1.36-.703 2.252-1.94 2.444-3.43l.096-.646h-1.194c-.99 0-1.182.042-1.182.225 0 .477-.48 1.223-1.002 1.574l-.563.38-3.324.042c-3.873.056-4.601-.043-5.247-.717-.233-.253-.494-.633-.563-.844-.096-.239-.15-1.813-.15-4.048 0-4.006.04-4.231.81-4.976l.44-.422 4.065-.042 4.065-.042.508.351c.522.351.961 1.097.961 1.617 0 .28.07.295 1.182.295h1.194l-.082-.689zm16.509-1.28c-.426-.927-1.291-1.84-2.157-2.276-.644-.323-.686-.324-6.768-.366h-.016l-6.153-.042V30.28h2.335v-4.498H73.45l2.198 2.249 2.197 2.249h1.855v-1.617l-1.497-1.532-1.511-1.532.535-.281c.783-.38 1.635-1.322 2.047-2.207.33-.731.357-.9.357-2.952s-.028-2.221-.357-2.952zm17.387-.35l.042-1.167.04-1.153H81.212V30.28h15.52V28.03H83.546v-4.498h10.988v-2.25H83.546V16.925l6.565-.027 6.551-.043zm16.441.337c-.481-.998-1.195-1.757-2.115-2.221l-.838-.436H97.967V30.28H110.122l.824-.408c1.03-.492 1.868-1.434 2.266-2.516.275-.745.289-1.012.248-5.145-.042-4.203-.055-4.4-.357-5.018zm4.23 13.087V14.536h-2.198V30.28h2.198zM66.516 20.23v3.302h4.23c4.34 0 5.04-.07 5.645-.52.824-.646.906-.9.906-2.853 0-2.01-.096-2.263-1.03-2.924-.453-.31-.508-.31-5.096-.31h-4.655v3.304zm33.786 7.815V16.926h4.725c4.67 0 4.711 0 5.178.323.92.633.961.815.92 5.328-.041 3.837-.055 4.048-.33 4.428-.165.21-.494.52-.755.674-.44.268-.756.282-5.096.324l-4.642.042z"
        clipRule="evenodd"
      />
      <path d="M118.569 16.8v2.29l2.774 5.553 2.788 5.553 2.047.042 2.06.028 2.788-5.567 2.774-5.566V14.522h-2.06V18.5l-2.362 4.765-2.376 4.752-.811-.028-.81-.043-2.39-4.779-2.376-4.78v-3.865h-2.06l.014 2.277zM138.621 14.663c-1.222.267-2.5 1.335-3.063 2.6-.316.689-.316.773-.357 6.846l-.041 6.157h2.074v-4.498h11.125v4.498h2.073l-.041-6.227-.041-6.214-.343-.688c-.454-.914-1.291-1.743-2.198-2.18-.755-.365-.769-.365-4.669-.393-2.157-.014-4.189.042-4.519.099zm8.639 2.417c.989.69 1.03.83 1.071 3.782l.041 2.657h-11.166l.055-2.573c.041-2.39.055-2.586.357-3.093.632-1.026.824-1.068 5.261-1.082 3.859 0 3.941 0 4.381.31zM152.479 20.567c0 5.693.014 6.072.275 6.789.357.97 1.304 1.982 2.307 2.46l.783.38h8.515l.756-.366c.906-.436 1.799-1.321 2.225-2.207.315-.66.315-.703.357-6.888l.041-6.213h-2.074v5.806c0 5.735 0 5.805-.302 6.424-.618 1.223-.522 1.194-5.26 1.194-4.739 0-4.643.028-5.247-1.194-.316-.619-.316-.69-.316-6.424v-5.806h-2.06v6.045zM169.647 22.394v7.872h15.382v-2.249H171.844V14.522h-2.197v7.872zM184.755 15.647v1.124h6.592V30.266h2.06V16.77H200v-2.249H184.755v1.125z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H200V45H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default TitleIcon;
