import { urlBuilder } from './builder';

// opco
export const urlOrgManagmentDetail = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/details`);
export const urlOrgManagmentCreate = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/create`);
export const urlOrgManagmentEdit = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/edit`);
export const urlOrgManagmentProduct = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/product`);
export const urlOrgManagmentBilling = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/billing`);
export const urlOrgManagmentFees = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/fees`);
export const urlOrgManagmentFinancialAccs = urlBuilder(
  ({ id = ':id' }: { id: string }) => `/org-management/${id}/financial-accaunts`,
);

// users
export const urlOrgManagmentUsers = urlBuilder(({ id = ':id' }: { id: string }) => `/org-management/${id}/users`);
export const urlOrgManagmentUsersNew = urlBuilder(
  ({ id = ':id' }: { id: string }) => `/org-management/${id}/users/new`,
);
export const urlOrgManagmentUserDetails = urlBuilder(
  ({ id = ':id', userId = ':userId' }: { id: string; userId: string }) =>
    `/org-management/${id}/users/${userId}/details`,
);
export const urlOrgManagmentUserEdit = urlBuilder(
  ({ id = ':id', userId = ':userId' }: { id: string; userId: string }) => `/org-management/${id}/users/${userId}/edit`,
);
