/* eslint-disable @typescript-eslint/no-explicit-any */
const flattenObject = (obj: Record<any, any>) => {
  const flattened: Record<any, any> = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export default flattenObject;
