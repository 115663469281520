import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { store } from '../../store';
import setUserPassword from '../../api/public/setUserPassword';
import styles from './SetNewPassword.module.scss';
import urlHome from '../../url/urlHome';
import SetPasswordSuccess from './SetPasswordSuccess/SetPasswordSuccess';
import SetNewPasswordForm from '../../components/Forms/FormSetNewPassword/SetNewPasswordForm';
import Header from '../../components/Header';

const SetNewPassword: FC = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const history = useHistory();

  const {
    state: { authState },
  } = useContext(store);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      const { password } = values;

      return setUserPassword({ id, token, password: password as string })
        .then(() => {
          setSubmitted(true);
        })
        .catch((err) => {
          setErrors({ password: err.message });
        });
    },
    [id, token],
  );

  const handleGoLogin = useCallback(() => {
    history.push('/login');
  }, [history]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push(urlHome());
    }
  }, [authState.isAuthenticated, history]);

  return (
    <div className="blockLogin">
      <Header />
      <div className="formWrapper">
        <div className={styles.passwordBlock}>
          <div className={styles.passwordBlockForm}>
            <p className={styles.title}>Reset your password</p>
            {!submitted && (
              <div>
                <p className={styles.titleCreatePassword}>You will need to create a new password to continue.</p>
                <SetNewPasswordForm onSubmit={handleSubmit} onCancel={handleGoLogin} />
              </div>
            )}
            {submitted && <SetPasswordSuccess onClick={handleGoLogin} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
