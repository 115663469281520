import { AxiosResponse } from 'axios';
import instance from '../../instance';
import { TOrganization, TUserOpco } from '../../../../models';
import { TOrdering } from '../../../../components/Table';
import mkRequestOrdering from '../../../../utils/mkRequestOrdering';
import { TApiResponse } from '../../../../common';

export interface RequestParams {
  ordering?: TOrdering;
  current_status__in?: string;
  limit?: number;
  offset?: number;
}

const getList = async (params: RequestParams): Promise<TApiResponse<TOrganization[]>> => {
  const orderingParam = mkRequestOrdering(params?.ordering);
  const { limit, offset } = params ?? {};
  const requestParams = { params: { ordering: orderingParam, limit, offset } };

  return instance.get('/opco/organizations/', requestParams);
};

const getItem = async ({ id }: { id: string }): Promise<TApiResponse<TOrganization>> =>
  instance.get(`/opco/organizations/${id}`);

const getCSV = async (): Promise<AxiosResponse<string>> => instance.get('/opco/organizations.csv/');
const getUserOpCo = async ({ id }: { id: string }, params?: RequestParams): Promise<TApiResponse<TUserOpco[]>> => {
  const orderingParam = mkRequestOrdering(params?.ordering);
  const { limit, offset } = params ?? {};
  const requestParams = { params: { ordering: orderingParam, limit, offset } };

  return instance.get(`/opco/organizations/${id}/users`, requestParams);
};

const getUserDetailsOpCo = async ({ id, userId }: { id: string; userId: string }): Promise<TApiResponse<TUserOpco>> =>
  instance.get(`/organizations/${id}/users/${userId}`);

export { getList, getItem, getCSV, getUserOpCo, getUserDetailsOpCo };
