import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { store } from './store';
import Menu from './components/Menu/Menu';
import OrgManagement from './pages/OrgManagement';
import urlOrgManagement from './url/urlOrgManagement';
import urlHome from './url/urlHome';
import styles from './App.module.scss';
import NotificationIconHeader from './components/Icons/NotificationIconHeader';
import MessagesIconHeader from './components/Icons/MessageIconHeader';
import { MESSAGES_VARIANT, NOTIFICATION_VARIANT } from './components/Icons';
import SystemUserManagment from './pages/SystemUser';
import urlSystemUsers from './url/urlSystemUsers';
import Logout from './components/Logout';
import Home from './pages/Home';
import useEffectOnce from './utils/customHooks/useEffectOnce';
import { accessToken } from './utils/tokens';
import Loader from './components/Loader';

const App = () => {
  const history = useHistory();
  const {
    state: { authState, userState },
  } = useContext(store);
  const [allowRender, setAllowRender] = useState(false);
  const handleGetUserById = useCallback(async () => {
    const {
      auth: { userId },
    } = authState;
    if (userId) {
      await userState.getUserById({ id: userId });
    }
  }, [authState, userState]);

  useEffectOnce(async () => {
    await handleGetUserById();
  });

  useEffect(() => {
    if (!authState.isAuthenticated || !accessToken.get()) {
      history.push('/login');
    } else {
      setAllowRender(true);
    }
  }, [authState.isAuthenticated, history]);

  if (!allowRender) {
    return (
      <Loader enabled>
        <noscript />
      </Loader>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.menuWrapper}>
        <Menu />
      </div>
      <div className={styles.workAreaWr}>
        <div className={styles.icons}>
          <div className={styles.icon}>
            <MessagesIconHeader variant={MESSAGES_VARIANT.NEW_MESSAGE} />
          </div>
          <div className={styles.icon}>
            <NotificationIconHeader variant={NOTIFICATION_VARIANT.NEW_NOTIFICATION} />
          </div>
          <div className={styles.icon}>
            <Logout />
          </div>
        </div>
        <div className={styles.workArea}>
          <Switch>
            <Route path={urlHome()} component={Home} />
            <Route path={urlOrgManagement()} component={OrgManagement} />
            <Route path={urlSystemUsers()} component={SystemUserManagment} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default App;
