import React from 'react';

const ActivityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="none" viewBox="0 0 18 16">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 13h18v-2.002H0V13zm0 3h18v-1H0v1zm0-7h18V6H0v3zm0-9v4h18V0H0z"
      clipRule="evenodd"
      opacity=".9"
    />
  </svg>
);

export default ActivityIcon;
