import React from 'react';

const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none" viewBox="0 0 22 20">
    <path
      fill="#F26A50"
      fillRule="evenodd"
      d="M9.718 12.5h1.767v1.667H9.718V12.5zm0-6.667h1.767v5H9.718v-5zm.874-4.166C5.716 1.667 1.767 5.4 1.767 10s3.949 8.333 8.825 8.333c4.886 0 8.844-3.733 8.844-8.333s-3.958-8.333-8.844-8.333zm.01 15c-3.906 0-7.068-2.984-7.068-6.667S6.696 3.333 10.6 3.333c3.905 0 7.068 2.984 7.068 6.667s-3.163 6.667-7.068 6.667z"
      clipRule="evenodd"
    />
  </svg>
);
export default ErrorIcon;
