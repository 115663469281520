import React, { Dispatch, FC, SetStateAction, useCallback, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ErrorMessage, Formik, FormikHelpers, FormikValues } from 'formik';
import styles from './LoginForm.module.scss';
import { store } from '../../../store';
import Button, { BUTTON_VARIANTS } from '../../Button';
import Checkbox from '../../Checkbox';
import { CHECKBOX_VARIANT } from '../../Checkbox/const';
import urlRecoveryPassword from '../../../url/urlRecoveryPassword';
import BadEmailOrPassword from '../../BadEmailOrPassword';
import { initialValues, validationSchemaLogin } from '../../../utils/patterns/patterns';
import InputComponent, { INPUT_VARIANT } from '../../Input';
import Loader from '../../Loader';

interface ILoginForm {
  onSubmit: (values: FormikValues, helpers: FormikHelpers<{ email: string; password: string }>) => void;
  requestError?: string;
  setRequestError: Dispatch<SetStateAction<string | undefined>>;
}

const badRequestMessage =
  'Oops, it looks like that information is invalid. You can always reset your account or contact your admin.';

const badRequest = (requestError: string | undefined) =>
  requestError && <BadEmailOrPassword message={badRequestMessage} />;

const badRequestEmail = (requestError: string | undefined) =>
  requestError && <BadEmailOrPassword message={requestError} />;

const wrongEmail = <ErrorMessage name="email">{(msg) => <BadEmailOrPassword message={msg} />}</ErrorMessage>;
const wrongPassword = <ErrorMessage name="password">{(msg) => <BadEmailOrPassword message={msg} />}</ErrorMessage>;

const LoginForm: FC<ILoginForm> = ({ onSubmit, requestError, setRequestError }) => {
  const {
    state: { authState },
  } = useContext(store);

  const [rememberMe, setRememberMe] = useState(false);

  const handlerRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const hideError = () => {
    setRequestError(undefined);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const errorsTypeEmail = useCallback(
    (errors: any, touched: any) => (errors.email && touched.email) || requestError,
    [requestError],
  );
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const errorsTypePassword = useCallback(
    (errors: any, touched: any) => (errors.password && touched.password) || requestError,
    [requestError],
  );

  if (authState.isAuthenticated !== true) {
    authState.isLoading = false;
  }

  return (
    <Loader enabled={authState.isLoading}>
      <Formik validationSchema={validationSchemaLogin} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <span className={styles.welcomeTitle}>Welcome back</span>
            <span className={styles.detailsTitle}> Enter your details below</span>
            <div>
              <>
                <div className={styles.titleBlock}>
                  <span className={errorsTypeEmail(errors, touched) ? styles.error : styles.title}>
                    Email Address :
                  </span>
                  <NavLink to={urlRecoveryPassword()} className={styles.navLink}>
                    Forgot Username?
                  </NavLink>
                </div>
                <div className={styles.inputBlock}>
                  <InputComponent
                    onChange={handleChange}
                    name="email"
                    variant={errorsTypeEmail(errors, touched) ? INPUT_VARIANT.ERROR_INPUT : INPUT_VARIANT.LOGIN_INPUT}
                    values={values}
                    onClick={hideError}
                    placeholder="email"
                  />
                </div>
              </>
              {errors.email ? wrongEmail : ''}
              {badRequestEmail ? badRequestEmail(requestError) : ''}
              <>
                <div className={styles.titleBlock}>
                  <span className={errorsTypePassword(errors, touched) ? styles.error : styles.title}>Password :</span>
                  <NavLink to={urlRecoveryPassword()} className={styles.navLink}>
                    Forgot Password?
                  </NavLink>
                </div>
                <div className={styles.inputBlock}>
                  <InputComponent
                    onChange={handleChange}
                    name="password"
                    variant={
                      errorsTypePassword(errors, touched) ? INPUT_VARIANT.ERROR_INPUT : INPUT_VARIANT.LOGIN_INPUT
                    }
                    type="password"
                    values={values}
                    onClick={hideError}
                    placeholder="password"
                  />
                </div>
              </>
              {errors.password ? wrongPassword : ''}
              {badRequest ? badRequest(requestError) : ''}
            </div>
            <div className={styles.checkBoxWrapper}>
              <Checkbox
                variant={CHECKBOX_VARIANT.LOG_IN}
                check={rememberMe}
                onChange={handlerRememberMe}
                label="Remember Username"
              />
            </div>
            <Button variant={BUTTON_VARIANTS.LOG_IN} type="submit">
              Log In
            </Button>
          </form>
        )}
      </Formik>
    </Loader>
  );
};

export default LoginForm;
