import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { BUTTON_VARIANTS, ICON_POSITION } from './const';

interface IButton {
  children: string;
  variant?: BUTTON_VARIANTS;
  icon?: JSX.Element;
  iconPosition?: ICON_POSITION;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  path?: string;
}

const VARIANT_STYLES_MAP = {
  [BUTTON_VARIANTS.EDIT]: styles.edit,
  [BUTTON_VARIANTS.SAVE]: styles.save,
  [BUTTON_VARIANTS.CANCEL]: styles.cancel,
  [BUTTON_VARIANTS.DEFAULT]: styles.button,
  [BUTTON_VARIANTS.GO_BACK]: styles.goBack,
  [BUTTON_VARIANTS.GO_LOGIN]: styles.goLogin,
  [BUTTON_VARIANTS.LOG_OUT]: styles.logOut,
  [BUTTON_VARIANTS.RECOVERY]: styles.recoveryPassword,
  [BUTTON_VARIANTS.RETURN]: styles.return,
  [BUTTON_VARIANTS.CONTINUE]: styles.continue,
  [BUTTON_VARIANTS.DISABLED]: styles.disabled,
  [BUTTON_VARIANTS.LOG_IN]: styles.logIn,
  [BUTTON_VARIANTS.FORGOT_PASSWORD]: styles.forgotPassword,
};
const POSITION_STYLES_MAP = {
  [ICON_POSITION.LEFT]: styles.buttonIconLeft,
  [ICON_POSITION.RIGHT]: styles.button,
};

const Button: React.FC<IButton> = ({ children, variant, icon, iconPosition, onClick, type, disabled, path }) =>
  !path ? (
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={type !== 'submit' ? onClick : undefined}
      // eslint-disable-next-line react/button-has-type
      type={type}
      // eslint-disable-next-line react/button-has-type
      className={styles.container_button}
      // eslint-disable-next-line react/button-has-type
      disabled={disabled}
    >
      <div
        className={classNames(
          icon && iconPosition && POSITION_STYLES_MAP[iconPosition],
          VARIANT_STYLES_MAP[variant ?? BUTTON_VARIANTS.DEFAULT],
        )}
      >
        {icon && iconPosition === ICON_POSITION.LEFT && <div>{icon}</div>}
        <span>{children}</span>
        {icon && iconPosition === ICON_POSITION.RIGHT && <div>{icon}</div>}
      </div>
    </button>
  ) : (
    <Link
      // eslint-disable-next-line react/button-has-type
      type={type}
      // eslint-disable-next-line react/button-has-type
      className={styles.container_button}
      // eslint-disable-next-line react/button-has-type
      to={path}
    >
      <div
        className={classNames(
          icon && iconPosition && POSITION_STYLES_MAP[iconPosition],
          VARIANT_STYLES_MAP[variant ?? BUTTON_VARIANTS.DEFAULT],
        )}
      >
        {icon && iconPosition === ICON_POSITION.LEFT && <div>{icon}</div>}
        <span>{children}</span>
        {icon && iconPosition === ICON_POSITION.RIGHT && <div>{icon}</div>}
      </div>
    </Link>
  );

Button.defaultProps = {
  variant: BUTTON_VARIANTS.DEFAULT,
  icon: undefined,
  iconPosition: undefined,
  type: 'button',
};

export default Button;
