import { TOrganization, TUserOpco } from '../../../../models';
import instance from '../../instance';
import { TApiResponse } from '../../../../common';

interface IUpdateOrg {
  id: string;
  payload: Partial<TOrganization>;
}

const updateOpcoOrganization = ({ id, payload }: IUpdateOrg): Promise<TApiResponse<TOrganization>> =>
  instance.put(`/opco/organizations/${id}/`, { payload });

const updateOpcoUser = ({
  id,
  userId,
  payload,
}: {
  id: string;
  userId: string;
  payload: TUserOpco;
}): Promise<TApiResponse<TOrganization>> => instance.patch(`/organizations/${id}/users/${userId}`, { payload });

export { updateOpcoOrganization, updateOpcoUser };
