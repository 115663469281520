import { useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { IInput } from '../../Models/FormModels';
import styles from './FieldSelect.module.scss';

const FieldSelect = ({ ...props }: IInput) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const ifValue = props?.values && props?.values[props.name];
  // @ts-ignore
  const onChange = (option) => {
    if (props.name === 'state') {
      // @ts-ignore
      props.setFieldValue(props.name, option.label);
    } else {
      // @ts-ignore
      props.setFieldValue(props.name, option.value);
    }
  };

  return (
    <div className={styles.fieldSelect}>
      <Select
        isSearchable={false}
        placeholder={props.placeholder}
        classNamePrefix="select"
        name={field.name}
        value={
          ifValue
            ? {
                label: props?.values && props?.values[props.name],
                value: props?.values && props?.values[props.name],
              }
            : null
        }
        options={props.options}
        onChange={onChange}
      />
      <div>{!!meta.error && meta.touched && <div className={styles.error}>{meta.error}</div>}</div>
    </div>
  );
};

export default FieldSelect;
