import React, { useState } from 'react';
import styles from './ToggleSwitcher.module.scss';
import { TOGGLE_SWITCHER_VARIANT } from './const';

interface IToggleSwitcher {
  name: string;
  variant: TOGGLE_SWITCHER_VARIANT;
}

const VARIANT_STYLES_MAP = {
  [TOGGLE_SWITCHER_VARIANT.YELLOW]: styles.input_fake,
};

const ToggleSwitcher = ({ name = '', variant = TOGGLE_SWITCHER_VARIANT.YELLOW }: IToggleSwitcher) => {
  const [check, setChecked] = useState(false);
  const handlerChange = () => {
    setChecked(!check);
  };

  return (
    <label className={styles.wrapper} htmlFor={name}>
      <input type="checkbox" id={name} className={styles.input} checked={check} onChange={handlerChange} />
      <span className={VARIANT_STYLES_MAP[variant]} />
    </label>
  );
};

export default ToggleSwitcher;
