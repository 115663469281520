import instance from './instance';
import { TApiResponse } from '../../common';

interface ISetUserPasswordRequest {
  id: string;
  token: string;
  password: string;
}

interface ISetUserPasswordResponse {
  ok: boolean;
}

const setUserPassword = async ({
  id,
  token,
  password,
}: ISetUserPasswordRequest): Promise<TApiResponse<ISetUserPasswordResponse>> =>
  instance.patch(`/users/${id}/set-password/${token}/`, {
    payload: {
      password,
    },
  });

export default setUserPassword;
