/* eslint-disable @typescript-eslint/no-explicit-any */

import { TApiResponse } from '../../../common';
import { TUser } from '../../../models';
import instance from '../instance';

export interface IGetUserRequest {
  id: string;
}

const getUserByIdRequest = async ({ id }: IGetUserRequest): Promise<TApiResponse<TUser>> =>
  instance.get(`/users/${id}`);

export default getUserByIdRequest;
