import { FC } from 'react';
import styles from './SetPasswordSuccess.module.scss';
import Button, { BUTTON_VARIANTS } from '../../../components/Button';

const SetPasswordSuccess: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div>
    <p className={styles.password_successfully}>You have successfully created a new password.</p>
    <div className={styles.button_successfully}>
      <Button onClick={onClick} variant={BUTTON_VARIANTS.GO_LOGIN}>
        Return to Log In
      </Button>
    </div>
  </div>
);

export default SetPasswordSuccess;
