import React, { useMemo } from 'react';
import styles from './Loader.module.scss';

interface LoaderProps {
  transparent?: boolean;
  children: JSX.Element | JSX.Element[];
  enabled?: boolean;
}

const Loader = ({ transparent, children, enabled }: LoaderProps) => {
  const classNames = useMemo(() => {
    const baseClass = enabled ? styles.backgroundWrActive : styles.backgroundWr;
    const additionalClass = transparent ? styles.backgroundTransparent : '';
    return `${baseClass} ${additionalClass}`;
  }, [enabled, transparent]);

  return (
    <div className={styles.container}>
      {children}
      {enabled && (
        <div className={classNames}>
          <div className={styles.simpleLoader}>
            <div>
              <div />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Loader.defaultProps = {
  transparent: false,
  enabled: false,
};

export default Loader;
