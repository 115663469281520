import { createTheme } from '@material-ui/core/styles';
import React from 'react';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    colors: {
      white: React.CSSProperties['color'];
      menu: {
        gradient: {
          start: React.CSSProperties['color'];
          end: React.CSSProperties['color'];
        };
      };
      workArea: {
        bg: React.CSSProperties['color'];
        header: React.CSSProperties['color'];
      };
    };
  }
  interface ThemeOptions {
    colors: {
      white: React.CSSProperties['color'];
      menu: {
        gradient: {
          start: React.CSSProperties['color'];
          end: React.CSSProperties['color'];
        };
      };
      workArea: {
        bg: React.CSSProperties['color'];
        header: React.CSSProperties['color'];
      };
    };
  }
}

const theme = createTheme({
  colors: {
    white: '#ffffff',
    menu: {
      gradient: {
        start: '#133e67',
        end: '#17b0e4',
      },
    },
    workArea: {
      bg: '#d9dfe3',
      header: '#f5f6f7',
    },
  },
  palette: {
    primary: {
      main: '#17b0e4',
    },
  },
});

export default theme;
