import React from 'react';
import ErrorSignIn from '../Icons/ErrorSignIn';
import styles from './BadEmailOrPassword.module.scss';

interface IBadEmailOrPassword {
  message: string | undefined;
}

const BadEmailOrPassword: React.FC<IBadEmailOrPassword> = ({ message }) => (
  <div className={styles.errorMessage}>
    <ErrorSignIn />
    <p className={styles.errMessage}>{message}</p>
  </div>
);

BadEmailOrPassword.defaultProps = {
  message: undefined,
};

export default BadEmailOrPassword;
