import React from 'react';

const MessagesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" fill="none" viewBox="0 0 16 18">
    <path
      fill="#fff"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M16.2 0H1.8C.81 0 .009.9.009 2L0 14c0 1.1.81 2 1.8 2h14.4c.99 0 1.8-.9 1.8-2V2c0-1.1-.81-2-1.8-2zm0 4L9 9 1.8 4V2L9 7l7.2-5v2z"
      clipRule="evenodd"
    />
  </svg>
);
export default MessagesIcon;
