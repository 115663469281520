import React from 'react';

const ErrorSignIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="#F26A50"
      fillRule="evenodd"
      d="M9.167 12.5h1.666v1.667H9.167V12.5zm0-6.667h1.666v5H9.167v-5zm.825-4.166c-4.6 0-8.325 3.733-8.325 8.333s3.725 8.333 8.325 8.333c4.608 0 8.341-3.733 8.341-8.333S14.6 1.667 9.992 1.667zm.008 15c-3.683 0-6.667-2.984-6.667-6.667S6.317 3.333 10 3.333 16.667 6.317 16.667 10 13.683 16.667 10 16.667z"
      clipRule="evenodd"
    />
  </svg>
);
export default ErrorSignIn;
