import React from 'react';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  label: string;
  renderControls?: () => JSX.Element;
}

const PageHeader: React.FC<PageHeaderProps> = ({ label, renderControls }) => (
  <div className={styles.pageHeader}>
    <span className={styles.labelWr}>{label}</span>
    <div className={styles.controlsWr}>{renderControls && renderControls()}</div>
  </div>
);

export default PageHeader;
