import instance from '../instance';
import { TApiResponse } from '../../../common';

export interface ITermsResponse {
  id: string;
  is_approved: boolean;
  content: string;
  last_diff: null;
}

export interface ITermsRequest {
  id: string;
}

const getTerms = async (): Promise<TApiResponse<ITermsResponse>> => instance.get('/users/terms/');

const postTerms = async (userId: string): Promise<TApiResponse<ITermsResponse>> =>
  instance.post('/users/terms/', {
    payload: {
      id: userId,
    },
  });

export { getTerms, postTerms };
