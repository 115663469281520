import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import TabsMenu, { MatchParams } from '../../../components/Tabs/TabsMenu';
import { useOrganizationState } from '../../../store/states/OrganizationState';
import useEffectOnce from '../../../utils/customHooks/useEffectOnce';
import PageHeader from '../../../components/PageHeader';
import NewProductForm from '../../../components/Forms/NewProductForm/NewProductForm';
import Loader from '../../../components/Loader';

const OrgManagmentProductCreate = () => {
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;
  const organizationState = useOrganizationState();
  const title = organizationState.organizationsItem?.name;

  const getOpcoDetails = useCallback(async () => {
    await organizationState.getOpcoOrganizationsItem({ id });
  }, [organizationState, id]);

  useEffectOnce(async () => {
    await getOpcoDetails();
  });

  return (
    <>
      <Loader enabled={organizationState.isLoading}>
        <PageHeader label={`${title || ''} | Product | Create`} />
        <TabsMenu />
        <NewProductForm />
      </Loader>
    </>
  );
};

export default OrgManagmentProductCreate;
