import getUserByIdRequest, { IGetUserRequest } from '../../api/private/user/get';
import { TUser } from '../../models';
import { TResponseErrors } from '../../common';
import { loading } from '../../utils/decorators/loading';

export interface IUserState {
  loading: boolean;
  requestError: TResponseErrors;
}

export default class UserState {
  loading: boolean = false;

  lastRequestedUser: TUser | null = null;

  @loading
  public async getUserById({ id }: IGetUserRequest) {
    const { data } = await getUserByIdRequest({ id });

    this.lastRequestedUser = data.result;
  }
}
