import { useMemo } from 'react';
import { LIMIT } from '../../const/various';
import styles from './Paginator.module.scss';

export interface IPaginator {
  page: number;
  setPage: Function;
  total?: number;
  limit?: number;
  hidePages?: boolean;
}

const Paginator: React.FC<IPaginator> = ({ page, setPage, total, limit, hidePages }) => {
  const totalPages = useMemo(() => (!total || !limit ? 0 : Math.ceil(total / limit) || 1), [limit, total]);
  const nextHandler = () => {
    setPage(page + 1);
  };
  const prevHandler = () => {
    setPage(page - 1);
  };

  if (total && limit && total < limit) {
    return null;
  }

  if (!total) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <button className={styles.paginationPrev} type="button" onClick={page > 1 ? prevHandler : () => {}}>
        Back
      </button>
      <div className={styles.paginationNumbers}>
        {hidePages ? (
          <>
            <span>{page}</span>&nbsp;of&nbsp;<span>{totalPages}</span>
          </>
        ) : (
          <>
            {Array.from(Array(totalPages).keys()).map((pageItem) => (
              <button
                className={pageItem + 1 === page ? styles.activePaginationButton : styles.paginationButton}
                key={`page_${pageItem}`}
                type="button"
                onClick={() => setPage(pageItem + 1)}
              >
                {pageItem + 1}
              </button>
            ))}
          </>
        )}
      </div>
      <button className={styles.paginationNext} type="button" onClick={page < totalPages ? nextHandler : () => {}}>
        Next
      </button>
    </div>
  );
};

Paginator.defaultProps = {
  total: 0,
  limit: LIMIT,
  hidePages: false,
};

export default Paginator;
