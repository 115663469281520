import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Terms.module.scss';
import Header from '../../components/Header';
import Button, { BUTTON_VARIANTS } from '../../components/Button';
import urlHome from '../../url/urlHome';
import { postTerms } from '../../api/private/terms/terms';
import Logout from '../../api/public/logout';
import { cleanupTokens } from '../../utils/tokens';

const Terms = () => {
  const history = useHistory();

  const rejectTerms = async () => {
    await Logout().then(() => {
      cleanupTokens();
    });
    history.push('/login');
  };

  const acceptTerms = async () => {
    const termsId = localStorage.getItem('termsId');
    await postTerms(termsId as string)
      .then(() => {
        history.push(urlHome());
      })
      .catch(() => {
        history.push('/login');
      });
  };

  return (
    <div className="blockLogin">
      <Header />
      <div className={styles.termsBlockWrapper}>
        <div className={styles.blockTitle}>
          <p className={styles.blockTitle_terms}>Terms and Conditions</p>
          <p className={styles.blockTitle_info}>Our terms & conditions have changed</p>
          <p className={styles.blockTitle_info}>Please read them carefully before continuing to log-in.</p>
        </div>
        <div className={styles.blockTerms}>
          <div className={styles.blockTerms_info}>
            <p className={styles.title}>This is Important Legal Info</p>
            <p className={styles.terms}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Egestas erat imperdiet sed euismod nisi porta. Consectetur libero id faucibus nisl
              tincidunt eget nullam. A arcu cursus vitae congue mauris. Vitae congue mauris rhoncus aenean vel elit
              scelerisque. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida hendrerit. Interdum
              consectetur libero id faucibus. Viverra aliquet eget sit amet tellus cras adipiscing enim. Fringilla urna
              porttitor rhoncus dolor purus non enim praesent elementum. Phasellus faucibus scelerisque eleifend donec
              pretium. Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Nisi scelerisque eu ultrices
              vitae auctor eu augue ut lectus. Sed adipiscing diam donec adipiscing tristique risus nec feugiat in.
              Pharetra et ultrices neque ornare aenean euismod elementum. Facilisi cras fermentum odio eu feugiat
              pretium nibh ipsum consequat. Vitae nunc sed velit dignissim sodales. Sed odio morbi quis commodo. Leo
              urna molestie at elementum eu facilisis. Sit amet cursus sit amet dictum sit amet justo. Auctor urna nunc
              id cursus metus aliquam eleifend mi. Sapien faucibus et molestie ac feugiat sed. Ac turpis egestas
              maecenas pharetra convallis. Malesuada fames ac turpis egestas integer eget aliquet. Vulputate dignissim
              suspendisse in est. Mattis molestie a iaculis at erat. Lobortis feugiat vivamus at augue eget arcu. Tortor
              condimentum lacinia quis vel eros donec ac odio. Pharetra convallis posuere morbi leo{' '}
            </p>
          </div>
          <div className={styles.buttonBlock}>
            <Button variant={BUTTON_VARIANTS.GO_BACK} type="button" onClick={rejectTerms}>
              Reject
            </Button>
            <Button onClick={acceptTerms} variant={BUTTON_VARIANTS.CONTINUE}>
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
