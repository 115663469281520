import React from 'react';
import Select, { ActionMeta } from 'react-select';
import { ISelect } from '../../interfaces/ISelect';
import { IValues } from '../Forms/Models/FormModels';
import styles from './Select.module.scss';

export interface ISelectComponent {
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isRtl?: boolean;
  options: Array<ISelect>;
  onChange: (value: ISelect | null, actionMeta: ActionMeta<ISelect> | object) => void;
  placeholder?: string;
  values?: IValues;
  value?: string | object | null;
  name?: string;
  error?: object;
  touched?: object;
  className?: string;
}

const SelectComponent = ({
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  options,
  onChange,
  placeholder,
  name,
  values,
  value,
  error,
  touched,
  className,
  ...props
}: ISelectComponent) => (
  <div>
    <Select
      {...props}
      placeholder={placeholder}
      className={`${styles.select} ${className}`}
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      name={name}
      options={options}
      onChange={onChange}
      onSelect={onChange}
      // @ts-ignore
      value={values[name]}
    />
    <div>{!!error && touched && <div style={{ color: 'red', marginTop: '1rem' }}>{error}</div>}</div>
  </div>
);

SelectComponent.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isRtl: false,
  isSearchable: false,
  placeholder: null,
  onChange: () => {},
  values: {},
  name: '',
  className: 'select-wrapper',
};

export default SelectComponent;
