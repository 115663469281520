import { SvgIconComponent } from '@material-ui/icons';
import { Permission, permissionsUserRoleMap } from '../../const/permissions';
import urlOrgManagement from '../../url/urlOrgManagement';
import urlHome from '../../url/urlHome';
import HomeIcon from '../Icons/HomeIcon';
import OrgManagmentIcon from '../Icons/OrgManagmentIcon';
import ActivityIcon from '../Icons/ActivityIcon';
import NotificationIcon from '../Icons/NotyficationIcon';
import MessagesIcon from '../Icons/MessagesIcon';
import SystemUsers from '../Icons/SystemUsersIcon';
import urlActivity from '../../url/urlActivity';
import urlNotifications from '../../url/urlNotifications';
import urlMessages from '../../url/urlMessages';
import urlSystemUsers from '../../url/urlSystemUsers';
import urlOrgSettings from '../../url/urlOrgSettings';
import { UserType } from '../../enums';

type MenuItemData = {
  label: string;
  icon: SvgIconComponent;
  basePath: string;
  permission?: keyof typeof permissionsUserRoleMap;
  show?: boolean;
};

enum MENU_ITEM {
  HOME = 'home',
  ACTIVITY = 'activity',
  NOTIFICATIONS = 'notifications',
  MESSAGES = 'messages',
  ORG_MANAGEMENT = 'org_management',
  SYSTEM_USERS = 'system_users',
  ORG_SETTINGS = 'org_settings',
}

const MENU_ITEMS_DATA: { [key in MENU_ITEM]: MenuItemData } = {
  [MENU_ITEM.HOME]: {
    label: 'Home',
    icon: HomeIcon,
    basePath: urlHome(),
  },
  [MENU_ITEM.ACTIVITY]: {
    label: 'Activity',
    icon: ActivityIcon,
    basePath: urlActivity(),
  },
  [MENU_ITEM.NOTIFICATIONS]: {
    label: 'Notification',
    icon: NotificationIcon,
    basePath: urlNotifications(),
  },
  [MENU_ITEM.MESSAGES]: {
    label: 'Messages',
    icon: MessagesIcon,
    basePath: urlMessages(),
  },
  [MENU_ITEM.ORG_MANAGEMENT]: {
    label: 'Org Management',
    icon: OrgManagmentIcon,
    basePath: urlOrgManagement(),
    permission: Permission.organizationManagement,
  },
  [MENU_ITEM.SYSTEM_USERS]: {
    label: 'System Users',
    icon: SystemUsers,
    basePath: urlSystemUsers(),
    permission: Permission.organizationManagement,
  },
  [MENU_ITEM.ORG_SETTINGS]: {
    label: 'Org Settings',
    icon: OrgManagmentIcon,
    basePath: urlOrgSettings(),
    permission: Permission.userManagement,
  },
};

enum MENU_CATEGORY {
  MAIN = 'main',
  COMMUNICATION = 'communication',
  ADMIN_FUNCTION = 'admin_function',
  ADMINISTRATION = 'administration',
}

const MENU_CATEGORIES_DATA: { [key in MENU_CATEGORY]: { label: string } } = {
  [MENU_CATEGORY.MAIN]: {
    label: 'Main',
  },
  [MENU_CATEGORY.COMMUNICATION]: {
    label: 'Communication',
  },
  [MENU_CATEGORY.ADMIN_FUNCTION]: {
    label: 'Admin function',
  },
  [MENU_CATEGORY.ADMINISTRATION]: {
    label: 'Administration',
  },
};

const MENU_CATEGORIES_ITEMS_MAP: { [key in MENU_CATEGORY]: MENU_ITEM[] } = {
  [MENU_CATEGORY.MAIN]: [MENU_ITEM.HOME, MENU_ITEM.ACTIVITY],
  [MENU_CATEGORY.COMMUNICATION]: [MENU_ITEM.NOTIFICATIONS, MENU_ITEM.MESSAGES],
  [MENU_CATEGORY.ADMIN_FUNCTION]: [MENU_ITEM.ORG_MANAGEMENT, MENU_ITEM.SYSTEM_USERS],
  [MENU_CATEGORY.ADMINISTRATION]: [MENU_ITEM.ORG_SETTINGS],
};

const MENU_CATEGORIES_ROLE = {
  [UserType.OP_CO]: [MENU_CATEGORY.MAIN, MENU_CATEGORY.COMMUNICATION, MENU_CATEGORY.ADMIN_FUNCTION],
  [UserType.ORGANIZATION]: [MENU_CATEGORY.MAIN, MENU_CATEGORY.COMMUNICATION, MENU_CATEGORY.ADMINISTRATION],
};

export type { MenuItemData };

export {
  MENU_ITEM,
  MENU_ITEMS_DATA,
  MENU_CATEGORY,
  MENU_CATEGORIES_DATA,
  MENU_CATEGORIES_ITEMS_MAP,
  MENU_CATEGORIES_ROLE,
};
