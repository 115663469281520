import React, { useContext, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LogoutModal.module.scss';
import Button, { BUTTON_VARIANTS } from '../../Button';
import { cleanupTokens } from '../../../utils/tokens';
import { timeDiffCalc } from '../../../utils/dateUtils/dateUtils';
import LogOutIcon from '../../Icons/LogOutIcon';
import Clock from '../../Icons/Clock';
import useOnClickOutside from '../../../utils/customHooks/useOnClickOutsideHook';
import { store } from '../../../store';
import { TUser } from '../../../models';

interface ILogoutModal {
  onClose: () => void;
}

const LogoutModal = ({ onClose }: ILogoutModal) => {
  const {
    state: { userState },
  } = useContext(store);

  const {
    state: { authState },
  } = useContext(store);

  const history = useHistory();
  const ref = useRef(null);
  useOnClickOutside(ref, () => onClose());

  const { userEmail, lastTimeLogin } = useMemo(() => {
    const { email, last_time_login } = userState.lastRequestedUser ?? ({} as TUser);
    return { userEmail: email, lastTimeLogin: last_time_login };
  }, [userState.lastRequestedUser]);

  const handlerLogout = async () => {
    await authState.logOut().then(() => cleanupTokens());
    history.push('/login');
  };

  const timeSinceLastLogin = useMemo(() => timeDiffCalc(lastTimeLogin), [lastTimeLogin]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.modalBlock}>
        <div className={styles.userInfoBlock}>
          <div className={styles.userInfoLogo}>
            <LogOutIcon />
          </div>
          <div className={styles.title}>{userEmail}</div>
          <div className={styles.wrapperTimeBlock}>
            <Clock />
            <div className={styles.timeBlock}>
              <span className={styles.timeBlockText}>last log-in:</span>
              <span className={styles.timeBlockText}>{timeSinceLastLogin}</span>
            </div>
          </div>
        </div>
      </div>
      <Button variant={BUTTON_VARIANTS.LOG_OUT} onClick={handlerLogout}>
        Log Out
      </Button>
    </div>
  );
};

export default LogoutModal;
