import { Formik, FormikHelpers, FormikValues } from 'formik';
import { FC, useState } from 'react';
import { initialValuesSetNewPassword, validationSchemaSetNewPassword } from '../../../utils/patterns/patterns';
import styles from './SetNewPassword.module.scss';
import ErrorIcon from '../../Icons/ErrorIcon';
import Button, { BUTTON_VARIANTS } from '../../Button';
import InformationModal from '../../../pages/SetNewPassword/InformationModal/InformationModal';

interface ISetNewPasswordForm {
  onSubmit: (values: FormikValues, helpers: FormikHelpers<{ password: string; passwordConfirmation: string }>) => void;
  onCancel: () => void;
}

const SetNewPasswordForm: FC<ISetNewPasswordForm> = ({ onSubmit, onCancel }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Formik
      validationSchema={validationSchemaSetNewPassword}
      initialValues={initialValuesSetNewPassword}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.inputBlock}>
            <div className={styles.infoBlock}>
              <p className={errors.password && touched.password ? styles.inputTitleError : styles.inputTitle}>
                Password:
              </p>
              <button className={styles.modalButton} type="button" onClick={() => setModalVisible(!modalVisible)}>
                i
              </button>
              {modalVisible && <InformationModal />}
            </div>
            <input
              name="password"
              type="password"
              onChange={handleChange}
              value={values.password}
              className={errors.password && touched.password ? styles.inputError : styles.input}
            />
            {errors.password && touched.password && (
              <div>
                <ErrorIcon />
                <span className={styles.errorMessage}>{errors.password}</span>
              </div>
            )}
            <p className={styles.inputTitle}> Verify password :</p>
            <input
              name="passwordConfirmation"
              type="password"
              onChange={handleChange}
              value={values.passwordConfirmation}
              className={errors.passwordConfirmation && touched.passwordConfirmation ? styles.inputError : styles.input}
            />
            {errors.passwordConfirmation && touched.passwordConfirmation && (
              <div>
                <ErrorIcon />
                <span className={styles.errorMessage}>{errors.passwordConfirmation}</span>
              </div>
            )}
          </div>
          <div className={styles.buttonBlock}>
            <Button onClick={onCancel} variant={BUTTON_VARIANTS.GO_BACK}>
              Go Back
            </Button>
            <Button variant={BUTTON_VARIANTS.CONTINUE} type="submit">
              Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SetNewPasswordForm;
