import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './NewUserForm.module.scss';
import { CREATE_NEW_USER } from '../FormData/FormData';
import Form from '../form';
import { useOrganizationState } from '../../../store/states/OrganizationState';
import { MatchParams } from '../../Tabs/TabsMenu';
import { urlOrgManagmentUsers } from '../../../url/urlOrgManagementItem';
import flattenObject from '../../../utils/flattenObject';
import { TUserOpco } from '../../../models';
import { toLowerLetter } from '../../../utils/toLowerLetter';
import { capitalise } from '../../../utils/capitalize';
import { validationSchemaSystemUser } from '../../../utils/patterns/patterns';

interface Iprops {
  userInfo: TUserOpco | null;
  editMode: boolean;
}

const NewUserForm = ({ userInfo, editMode }: Iprops) => {
  const match = useRouteMatch();
  const { id, userId }: MatchParams = match.params;
  const history = useHistory();
  const organizationState = useOrganizationState();
  const fieldsItems = userInfo
    ? CREATE_NEW_USER.map((item) => {
        const newItem = { ...item };
        Object.keys(userInfo).forEach((userItem) => {
          if (newItem.name === userItem) {
            // @ts-ignore
            newItem.value = userInfo[userItem];
          }
          if (editMode && newItem.name === 'email') {
            newItem.value = userInfo.email;
          }
          if (editMode && newItem.name === 'name') {
            newItem.value = userInfo.name;
          }
          if (editMode && newItem.name === 'status') {
            newItem.value = capitalise(userInfo.status);
          }
          if (editMode && newItem.name === 'role') {
            newItem.value = capitalise(userInfo.role);
          }
        });
        return newItem;
      })
    : CREATE_NEW_USER;

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      const payload = {
        name: values.name,
        email: values.email,
        status: toLowerLetter(values.status),
        role: toLowerLetter(values.role),
      };

      if (editMode) {
        await organizationState
          ?.updateOpcoOrganizationUser({ payload, userId, id })
          .then(() => {
            history.push(urlOrgManagmentUsers({ id }));
          })
          .catch((e) => {
            setErrors(flattenObject(e.response.data.error_content));
          });
      }
      if (!editMode) {
        await organizationState
          ?.createOpcoOrganizationUser({ payload, id })
          .then(() => {
            history.push(urlOrgManagmentUsers({ id }));
          })
          .catch((e) => {
            setErrors(flattenObject(e.response.data.error_content));
          });
      }
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [id, history, organizationState],
  );

  return (
    <Form
      validationScheme={validationSchemaSystemUser}
      fields={fieldsItems}
      className={styles.formWidth}
      onSubmit={handleSubmit}
      editMode={editMode}
      userInfo={userInfo}
    />
  );
};

export default NewUserForm;
