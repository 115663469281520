import React, { useCallback, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './NewSystemUserForm.module.scss';
import { CREATE_NEW_SYSTEM_USER } from '../FormData/FormData';
import Form from '../form';
import { useSystemUsersState } from '../../../store/states/SystemUser.State';
import { store } from '../../../store';
import urlSystemUsers from '../../../url/urlSystemUsers';
import flattenObject from '../../../utils/flattenObject';
import { TSystemUser } from '../../../models';
import { MatchParams } from '../../Tabs/TabsMenu';
import { toLowerLetter } from '../../../utils/toLowerLetter';
import { capitalise } from '../../../utils/capitalize';
import { accessToken, getOpcoIdFromToken } from '../../../utils/tokens';
import { validationSchemaSystemUser } from '../../../utils/patterns/patterns';

interface Iprops {
  userInfo: TSystemUser | null;
  editMode: boolean;
}

const NewSystemUserForm = ({ userInfo, editMode }: Iprops) => {
  const {
    state: { authState },
  } = useContext(store);
  const opcoId = authState.opcoId || getOpcoIdFromToken(accessToken.get());
  const { userId } = authState;
  const systemUserState = useSystemUsersState();
  const history = useHistory();
  const match = useRouteMatch();
  const { id }: MatchParams = match.params;

  const systemUsers = userInfo
    ? CREATE_NEW_SYSTEM_USER.map((item) => {
        const newItem = { ...item };
        if (userId === id && newItem.name === 'status') {
          // @ts-ignore
          newItem.options = newItem.options.filter((status) => status.value !== 'blocked');
        }
        Object.keys(userInfo).forEach((userItem) => {
          if (newItem.name === userItem) {
            // @ts-ignore
            newItem.value = userInfo[userItem];
          }
          if (userId === id) {
            newItem.label = userInfo.status;
          }
          if (editMode && newItem.name === 'role') {
            newItem.value = capitalise(userInfo.role);
          }
          if (editMode && newItem.name === 'status') {
            newItem.value = capitalise(userInfo.status);
          }
          if (editMode && newItem.name === 'name') {
            newItem.value = userInfo.name;
          }
          if (editMode && newItem.name === 'email') {
            newItem.value = userInfo.email;
          }
        });
        return newItem;
      })
    : CREATE_NEW_SYSTEM_USER;

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      const payload = {
        name: values.name,
        email: values.email,
        status: toLowerLetter(values.status),
        role: toLowerLetter(values.role),
      };
      if (editMode) {
        await systemUserState
          ?.updateSystemUser({ payload, opcoId, id })
          .then(() => {
            history.push(urlSystemUsers());
          })
          .catch((e) => {
            setErrors(flattenObject(e.response.data.error_content));
          });
      }
      if (!editMode) {
        await systemUserState
          ?.createSystemUser({ payload, id })
          .then(() => {
            history.push(urlSystemUsers());
          })
          .catch((e) => {
            setErrors(flattenObject(e.response.data.error_content));
          });
      }
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [id, history, systemUserState],
  );

  return (
    <Form
      validationScheme={validationSchemaSystemUser}
      fields={systemUsers}
      className={styles.formWidth}
      editMode={editMode}
      userInfo={userInfo}
      onSubmit={handleSubmit}
    />
  );
};

export default NewSystemUserForm;
