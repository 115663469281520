import { TApiResponse } from '../../../common';
import { TSystemUser } from '../../../models';
import instance from '../instance';

interface ISystemUser {
  id: string;
  opcoId: string;
  payload: Partial<TSystemUser>;
}

const updateSystemUserDetail = async ({ id, opcoId, payload }: ISystemUser): Promise<TApiResponse<TSystemUser>> =>
  instance.patch(`opco/${opcoId}/users/${id}`, { payload });

export { updateSystemUserDetail };
