import React from 'react';
import styles from './Checkbox.module.scss';
import { CHECKBOX_VARIANT } from './const';

interface ICheckbox {
  variant: CHECKBOX_VARIANT;
  check: boolean;
  onChange: () => void;
  label: string;
}

const CHECKBOX_VARIANT_MAP = {
  [CHECKBOX_VARIANT.LOG_IN]: styles.fakeCheckBox,
  [CHECKBOX_VARIANT.FINANCE_ACCOUNT]: styles.fakeCheckBox_financeAccount,
};

const Checkbox = ({ variant, check, onChange, label }: ICheckbox) => (
  // eslint-disable-next-line
  <label className={styles.container}>
    {label}
    <input className={styles.input} type="checkbox" checked={check} onChange={onChange} />
    <span className={CHECKBOX_VARIANT_MAP[variant]} />
  </label>
);

export default Checkbox;
