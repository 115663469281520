export interface ISelect {
  label: string;
  value: string;
}
export const SystemUserRoleSelect = [
  { value: 'opco', label: 'OpCo' },
  { value: 'admin', label: 'Admin' },
  { value: 'sales', label: 'Sales' },
  { value: 'operator', label: 'Operator' },
  { value: 'finance', label: 'Fanance' },
];

export const SystemUserStatusSelect = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
];
export const OpcoUserSelect = [
  { value: 'new user', label: 'New User' },
  { value: 'active user', label: 'Active User' },
];
