import { useLocation, NavLink } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { MenuItemData } from '../conts';
import { hasPermission } from '../../../const/permissions';
import { BASE_URL } from '../../../url/builder';
import styles from './MenuItem.module.scss';
import { store } from '../../../store';

const MenuItem: React.FC<MenuItemData> = ({ label, basePath, icon, permission }) => {
  const {
    state: { userState },
  } = useContext(store);
  const userType = useMemo(() => userState.lastRequestedUser?.type, [userState.lastRequestedUser?.type]);
  const { pathname } = useLocation();
  const Icon = useMemo(() => icon, [icon]);
  const isActive = useMemo(() => pathname.startsWith(basePath), [basePath, pathname]);

  if (!userType || (permission && !hasPermission(permission, [userType]))) {
    return null;
  }

  return (
    <NavLink to={basePath || BASE_URL} className={styles.linkWrapper}>
      <span className={styles.iconWrapper}>
        <Icon />
      </span>
      <span className={`${styles.linkText} ${isActive ? styles.linkActive : ''}`}>{label}</span>
    </NavLink>
  );
};

export default MenuItem;
