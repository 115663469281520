export const BASE_URL = '/app';

export const urlBuilder =
  (builder: Function, skipBasePath: boolean = false) =>
  (...paramObjects: Array<object>) => {
    const baseUrl = skipBasePath ? '' : BASE_URL;

    const baseUrlHasSlash = String(baseUrl).endsWith('/');
    const url = builder(Object.assign({}, ...paramObjects));
    const urlHasSlash = String(url).startsWith('/');

    if (!baseUrlHasSlash && urlHasSlash) {
      return `${baseUrl}${url}`;
    }

    if (baseUrlHasSlash && !urlHasSlash) {
      return `${baseUrl}${url}`;
    }

    if (baseUrlHasSlash && urlHasSlash) {
      return `${baseUrl}${url.slice(0)}`;
    }

    return `${baseUrl}${url.length > 0 ? '/' : ''}${url}`;
  };
